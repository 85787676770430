import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		ossDomain: '',
	},
	getters: {
		ossDomain: (state) => state.ossDomain,
	},
	mutations: {
		SET_OSSDOMAIN: (state, ossDomain) => {
			state.ossDomain = ossDomain
		},
	},
	actions: {},
	modules: {},
})
