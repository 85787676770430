<template>
	<div class="loading">
		<img src="../../assets/images/loading.gif" alt="" />
	</div>
</template>

<script>
export default {
	name: 'loading',

	components: {},
	methods: {},
}
</script>
<style lang="less" scoped>
.loading {
	width: 100vw;
	height: 100vh;
	background-color: #fff;
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	left: 0;
	top: 0;
	z-index: 99;
	img {
		width: 50%;
	}
}
</style>
