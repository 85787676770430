import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import apis from '@/api/index.js'
import ElementUI from 'element-ui'
import utils from '@/utils/util.js'
import 'element-ui/lib/theme-chalk/index.css'
Vue.config.productionTip = false
Vue.use(apis)
Vue.use(ElementUI)
Vue.use(utils)

new Vue({
	router,
	store,
	render: (h) => h(App),
	created() {
		// 实例创建完成后被立即调用rem换算方法,解决内容闪现问题
		setRemPc()
		window.addEventListener('resize', setRemPc) //浏览器窗口大小改变时调用rem换算方法
	},
}).$mount('#app')

//rem计算
function setRemPc() {
	let br = browserRedirect()
	let whdef
	if (br == 'web') {
		whdef = 100 / 390
	} else {
		whdef = 100 / 1920 // 表示1920的设计图,使用100PX的默认值
	}
	let bodyWidth = document.body.clientWidth // 当前窗口的宽度
	let rem = bodyWidth * whdef // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
	document.getElementsByTagName('html')[0].style.fontSize = rem + 'px'
}
//判断是移动设备还是电脑
function browserRedirect() {
	let sUserAgent = navigator.userAgent.toLowerCase();
	let bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
	let bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
	let bIsMidp = sUserAgent.match(/midp/i) == "midp";
	let bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
	let bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
	let bIsAndroid = sUserAgent.match(/android/i) == "android";
	let bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
	let bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
	if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
		return 'web'
	} else {
		return 'admin'
	}
}