<template>
    <div class="authention-info">
        <div class="screen-02" v-if="companyInfo">
            <div class="company-header-box">
                <div class="company-logo" v-if="!companyInfo.logo">

                </div>
                <img v-else :src="ossDomain + companyInfo.logo" class="company-logo" />
                <div class="company-info-box">
                    <div class="company-name">{{ companyInfo.name }}</div>
                    <div class="company-detail-box">
                        <div class="company-item">
                            <div class="name">认证证书</div>
                            <div class="num">{{ companyInfo.authRegionalBrandCount || 0 }}</div>
                        </div>
                        <div class="company-item">
                            <div class="name">相关产品</div>
                            <div class="num">{{ companyInfo.goodsCount || 0 }}</div>
                        </div>
                        <div class="company-item">
                            <div class="name">关联企业</div>
                            <div class="num">{{ companyInfo.guanlianqiye || 1 }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="company-detail-info-box">
                <div class="company-detail-info-item">
                    <div class="company-info-title">企业名称</div>
                    <div class="company-info-text">{{ companyInfo.name || '-' }}</div>
                </div>
                <div class="company-detail-info-item">
                    <div class="company-info-title">法定代表人</div>
                    <div class="company-info-text">{{ companyInfo.legalPerson || '-' }}</div>
                </div>
                <div class="company-detail-info-item">
                    <div class="company-info-title">统一信用代码</div>
                    <div class="company-info-text">{{ companyInfo.unifiedCode || '-' }}</div>
                </div>
                <div class="company-detail-info-item">
                    <div class="company-info-title">企业代码</div>
                    <div class="company-info-text">{{ companyInfo.code || '-' }}</div>
                </div>
                <div class="company-detail-info-item">
                    <div class="company-info-title">经营状况</div>
                    <div class="company-info-text">开业</div>
                </div>
                <div class="company-detail-info-item">
                    <div class="company-info-title">成立日期</div>
                    <div class="company-info-text">{{ companyInfo.establishDate || '-' }}</div>
                </div>
                <div class="company-detail-info-item">
                    <div class="company-info-title">行政区划</div>
                    <div class="company-info-text">{{ companyInfo.areaName || '-' }}</div>
                </div>
                <div class="company-detail-info-item">
                    <div class="company-info-title">注册资本</div>
                    <div class="company-info-text">{{ companyInfo.registerCapital || '-' }}（万元）</div>
                </div>
                <div class="company-detail-info-item">
                    <div class="company-info-title">企业类型</div>
                    <div class="company-info-text">{{ companyInfo.companyType || '-' }}</div>
                </div>
                <div class="company-detail-info-item">
                    <div class="company-info-title">所属行业</div>
                    <div class="company-info-text">{{ companyInfo.industryType || '-' }}</div>
                </div>
                <div class="company-detail-info-item">
                    <div class="company-info-title">登记机关</div>
                    <div class="company-info-text">{{ companyInfo.registerOffice || '-' }}</div>
                </div>
                <div class="company-detail-info-item">
                    <div class="company-info-title">参保人数</div>
                    <div class="company-info-text">{{ companyInfo.canbao || '-' }}</div>
                </div>
                <div class="company-detail-info-item" style="min-width: 100%;">
                    <div class="company-info-title">联系方式</div>
                    <div class="company-info-text">{{ companyInfo.customerContact || '-' }}</div>
                </div>
                <div class="company-detail-info-item" style="min-width: 100%;">
                    <div class="company-info-title">注册地址</div>
                    <div class="company-info-text">{{ companyInfo.address || '-' }}</div>
                </div>
                <div class="company-detail-info-item" style="min-width: 100%;">
                    <div class="company-info-title">经营范围</div>
                    <div class="company-info-text">{{ companyInfo.businessScope || '-' }}</div>
                </div>
            </div>
            <div class="authen_title">企业简介</div>
            <div class="authen_text">{{ companyInfo.imageDescritpion || '-' }}</div>
            <div class="authen_title">企业照片</div>

            <div class="authen_flex_box">
                <div class="authen_img_box" v-for="(item, index) in companyInfo.imgs" :key="index">
                    <img :src="ossDomain + item" alt="" srcset="">
                </div>

            </div>
            <div class="authen_title">企业品牌</div>
            <div class="authen_flex_box">
                <div class="authen_img_box" v-for="item in companyInfo.organizeBrandVos" :key="item.id">
                    <img :src="ossDomain + item.logo" alt="" srcset="">
                    <span>{{ item.name }}</span>
                </div>

            </div>

            <div class="authen_title">认证证书</div>
            <div class="authen_flex_box">
                <div class="authen_img_box" v-for="item in companyInfo.authRegionalBrandVos" :key="item.id">
                    <img src="../../assets/images/little_auth_img.png" @click="openAuthenDialog(item)" />
                </div>

            </div>
        </div>
        <div class="return-div" @click="eventReturn">返回</div>
        <div class="mask" v-if="showImageDialog" @click="showImageDialog = false">
            <div class="dialog_box">
                <div class="title">证书</div>
                <div class="content">

                    <img :src="dataURL" style="width:100%;" v-if="showImage">

                </div>
                <div class="btn_box">
                    <el-button size="small" @click="showImageDialog = false">确 定</el-button>
                </div>
            </div>
        </div>
        <div ref="imageTofile" v-if="downloadAuthen && !showImage" class="authen_container">
            <div class="authen_bg_box">
                <img src="../../assets/images/authen_bg_01.png" class="authen_bg_01" />
                <img src="../../assets/images/authen_bg_02.png" class="authen_bg_02" />
                <img :src="ossDomain + downloadAuthen.logo + '?' + randomString(6)" crossorigin="anonymous"
                    class="logo_01" />
                <img :src="ossDomain + downloadAuthen.logo + '?' + randomString(6)" crossorigin="anonymous"
                    class="logo_02" />
                <div class="title_01">地理标志证明商标</div>
                <div class="title_02">授权使用书</div>
                <div class="text_box">
                    <div class="text_01">第 {{ downloadAuthen.code }} 号 {{ downloadAuthen.name }}
                        地理标志证明商标已经中华人民共和国国家知识产权局商标局于 {{
            getYMD(downloadAuthen.registrationTime) }} 在商品分类第21类上注册。</div>
                    <div class="text_01">经本协会审核， {{ authenCompany }} 符合《茶船古道制地理商标证明商标使用管理条例》规定的使用条件，现授权你单位使用该商标</div>
                    <div class="text_01">授权书编号： {{ intoZero(downloadAuthen.orderNo, 10) }} </div>
                    <div class="text_01">有效期限 {{ getYMD(downloadAuthen.startTime) }} 至 {{
            getYMD(downloadAuthen.finishTime) }}
                    </div>
                </div>

                <div class="text_05">注 册 人：茶船古道协会</div>
                <div class="text_06">授权日期：{{ getYMD(downloadAuthen.startTime) }}</div>
                <div class="text_07">（盖章有效）</div>
                <div class="text_08">（此证不得转让、转租、转借或赠与他人使用）</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Message } from 'element-ui'
import area from '@/utils/areaJson'
import html2canvas from 'html2canvas'
export default {
    name: 'authentionInfo',
    components: {
        html2canvas,
    },
    data() {
        return {
            companyInfo: null,
            dataURL: '',
            showImageDialog: false,
            showImage: false,
            // 下载证书
            downloadAuthen: null,
            // 下载证书的公司
            authenCompany: '',
        }
    },
    computed: {
        ...mapGetters(['ossDomain']),
    },
    methods: {
        eventReturn() {
            this.$emit('eventReturn')
        },

        getInfo(id) {
            this.getOrganizeAuthInfoApi(id).then(res => {
                res.imgs = res.imgs.split(',')
                this.companyInfo = res

                // let provinceName = ''
                // let cityName = ''
                // let areaName = ''
                // area.forEach(item => {
                //     if (item.value == res.province) {
                //         provinceName = item.label
                //         item.children.forEach(item2 => {
                //             if (item2.value == res.city) {
                //                 cityName = item2.label
                //                 item2.children.forEach(item3 => {
                //                     if (item3.value == res.area) {
                //                         areaName = item3.label
                //                     }
                //                 })
                //             }
                //         })
                //     }
                // })
                // this.$set(this.companyInfo, 'areaName', provinceName + cityName + areaName)

            }).catch(err => {
                return false
            })
        },
        openAuthenDialog(data) {
            this.$loading()
            this.showImageDialog = true
            this.showImage = false
            this.authenCompany = this.companyInfo.name
            this.downloadAuthen = data

            this.$nextTick(() => {
                // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
                let _this = this;
                html2canvas(this.$refs.imageTofile, {
                    backgroundColor: null, //避免图片有白色边框
                    useCORS: true,
                    allowTaint: false,
                }).then((canvas) => {
                    let dataURL = canvas.toDataURL("image/png");
                    _this.dataURL = dataURL;
                    _this.showImage = true;
                    this.$loading().close()
                    _this.tableData = []
                })
            })

        },
        getYMD(data, year = 0) {
            let date = new Date(data)
            if (year > 0) {
                return (date.getFullYear() - 0 + year) + '年' + ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '月' + ((date.getDate()) < 10 ? '0' + (date.getDate()) : (date.getDate())) + '日'
            } else {
                return date.getFullYear() + '年' + ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '月' + ((date.getDate()) < 10 ? '0' + (date.getDate()) : (date.getDate())) + '日'
            }

        },
        intoZero(data, length) {
            let result = '' + data;
            console.log(result.length, length)
            while (result.length < length) {
                result = '0' + result
            }
            return result;
        },
        randomString(data) {
            return Math.floor(100000 + Math.random() * 900000);
        },
    },
    mounted() {
    },

}
</script>
<style lang="less" scoped>
.authention-info {
    background: #FFFFFF;
    border-radius: 10px;

    .return-div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 5vh;
        font-size: 14px;
        color: #999999;
        cursor: pointer;
        border-top: solid 1px #f2f2f2;
    }

    .screen-02 {
        width: calc(100% - 66px);
        padding: 33px;
        margin-top: 20px;
        height: 75vh;
        overflow: scroll;

        .company-header-box {
            display: flex;

            .company-logo {
                background-color: #6daae2;
                width: 98px;
                height: 98px;
                border-radius: 5px;
            }

            .company-info-box {
                margin-left: 20px;
                width: calc(100% - 98px);

                .company-name {
                    font-size: 28px;
                    font-weight: bold;
                    margin-bottom: 20px;
                }

                .company-detail-box {
                    display: flex;

                    .company-item {
                        margin: auto;

                        .name {
                            font-size: 14px;
                            color: #000000;
                        }

                        .num {
                            font-size: 14px;
                            color: #F2451B;
                        }
                    }
                }
            }
        }

        .company-detail-info-box {
            margin-top: 26px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .company-detail-info-item {
                flex: 1;
                min-width: 380px;
                display: flex;

                .company-info-title {
                    width: 100px;
                    background: #F6FAFF;
                    padding: 11px;
                    font-size: 14px;
                    border: 1px solid #E3EDFB;
                }

                .company-info-text {
                    flex: 1;
                    border: 1px solid #E3EDFB;
                    padding: 11px;
                    font-size: 14px;
                    color: #333333;
                }
            }
        }

        .authen_title {
            margin-top: 30px;
            font-size: 18px;
            color: #000000;
            font-weight: bold;
        }

        .authen_text {
            font-size: 13px;
            color: #333333;
            text-align: left;
            font-style: normal;
            text-transform: none;
            margin-top: 12px;
        }

        .authen_img_list {
            display: flex;

            div {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 158px;
                height: 158px;
                margin-right: 10px;

                img {
                    width: 100%;
                }
            }
        }

        .authen_flex_box {
            margin-top: 24px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .authen_img_box {
                margin-right: 17px;
                margin-bottom: 30px;
                width: 146px;
                height: 126px;
                font-size: 0;
                background: #F9F9F9;
                cursor: pointer;

                img {
                    width: 146px;
                    height: 126px;
                }
            }
        }
    }
}

.mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.5);

    .dialog_box {
        background: #FFFFFF;
        border-radius: 20px;
        width: 700px;
        margin: auto;
        box-shadow: 1px 1px 10px #888888;
        ;

        .title {
            font-size: 16px;
            line-height: 36px;
            border-bottom: 1px solid #ccc;
            padding: 10px 20px;
        }

        .content {
            font-size: 0;
            text-align: center;
            min-height: 100px;
        }

        .btn_box {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            border-top: 1px solid #ccc;
            padding: 10px 20px;
        }
    }
}

.authen_container {
    width: 828px;
    height: 1123px;
    position: fixed;
    top: 0;
    left: 9999999px;

    .authen_bg_box {
        position: relative;
        font-size: 0;
        width: 828px;
        height: 1123px;

        .authen_bg_01 {
            width: 828px;
            height: 1123px;
            position: absolute;
            top: 0;
            left: 0;
        }

        .authen_bg_02 {
            width: 634px;
            height: 287px;
            position: absolute;
            top: 112px;
            left: 97px;
        }

        .logo_01 {
            width: 128px;
            height: 128px;
            position: absolute;
            top: 109px;
            left: 350px;
        }

        .logo_02 {
            width: 108px;
            height: 108px;
            position: absolute;
            bottom: 206px;
            left: 137px;
        }

        .title_01 {
            font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
            font-weight: normal;
            font-size: 48px;
            color: #124383;
            line-height: 48px;
            position: absolute;
            top: 258px;
            left: 0;
            width: 100%;
            text-align: center;
        }

        .title_02 {
            font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
            font-weight: normal;
            font-size: 48px;
            color: #124383;
            line-height: 48px;
            position: absolute;
            top: 316px;
            left: 0;
            width: 100%;
            text-align: center;
        }

        .text_box {
            position: absolute;
            top: 420px;
            left: 104px;
            width: 619px;

            .text_01 {
                font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
                font-weight: normal;
                font-size: 21px;
                color: #124383;
                line-height: 41px;
                text-indent: 4ch;
            }
        }

        .text_05 {
            font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
            font-weight: normal;
            font-size: 32px;
            color: #124383;
            line-height: 41px;
            position: absolute;
            top: 812px;
            left: 294px;
        }

        .text_06 {
            font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
            font-weight: normal;
            font-size: 32px;
            color: #124383;
            line-height: 41px;
            position: absolute;
            top: 868px;
            left: 294px;
        }

        .text_07 {
            font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
            font-weight: normal;
            font-size: 21px;
            color: #124383;
            line-height: 41px;
            position: absolute;
            top: 917px;
            left: 436px;
        }

        .text_08 {
            font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
            font-weight: normal;
            font-size: 18px;
            color: #5B5B5B;
            line-height: 41px;
            text-align: center;
            width: 100%;
            position: absolute;
            top: 1016px;
            left: 0;
        }
    }
}
</style>
