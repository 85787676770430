<template>
    <div>
    </div>
</template>

<script>
export default {
    name: '',
    components: {},
    created() {
        this.browserRedirect()
    },
    methods: {
        //判断是移动设备还是电脑
        browserRedirect() {
            let sUserAgent = navigator.userAgent.toLowerCase();
            let bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
            let bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
            let bIsMidp = sUserAgent.match(/midp/i) == "midp";
            let bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
            let bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
            let bIsAndroid = sUserAgent.match(/android/i) == "android";
            let bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
            let bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
            if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
                //跳转移动端页面
                this.$router.push('/web/launch')
            } else {
                //跳转pc端页面
                this.$router.push('/admin/launch')
            }
        }
    },
}
</script>
<style lang="less" scoped></style>
