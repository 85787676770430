<template>
	<div class="launch">
		<img class="logo-img" src="../../assets/images/LOGO.png" alt="" />
		<div class="test-div">一片叶子、成就了一个产业、富裕了一方百姓</div>
		<div class="button-div" @click="goIndex">进入</div>
		<div class="launch-div">
			<img class="launch-img" src="../../assets/images/Launch.png" alt="" />
			<img class="launch-img2" src="../../assets/images/Launch-yes.png" alt="" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'launch',
	components: {},
	methods: {
		goIndex() {
			this.$router.push('/view/admin')
		},
	},
}
</script>
<style lang="less" scoped>
.launch {
	position: relative;
	width: 100%;
	height: 100%;
	background: linear-gradient(180deg, #eff0f4 66%, #f1f5f8 100%);
	display: flex;
	flex-direction: column;
	align-items: center;
	.logo-img {
		position: absolute;
		width: 1rem;
		bottom: 7.2rem;
		animation: logoAnimation 3s;
	}
	.test-div {
		position: absolute;
		font-size: 0.36rem;
		font-family: SCBold;
		font-weight: bold;
		color: #000000;
		letter-spacing: 0.14rem;
		bottom: 6.25rem;
		animation: testAnimation 3s;
	}
	.button-div {
		cursor: pointer;
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 0.99rem;
		height: 0.5rem;
		border: 0.01rem solid #000000;
		bottom: 5.26rem;
		font-size: 0.2rem;
		font-family: Source Han Serif CN-Heavy, Source Han Serif CN;
		font-weight: 800;
		color: #000000;
		animation: buttonAnimation 3s;
	}
	.launch-div {
		width: 8.94rem;
		height: 5.04rem;
		bottom: 0;
		position: absolute;
	}
	.launch-img {
		position: absolute;
		width: 100%;
		animation: launchAnimation 3.5s;
	}
	.launch-img2 {
		position: absolute;
		width: 100%;
		animation: launchAnimation2 4s;
	}
	@keyframes logoAnimation {
		0% {
			opacity: 0;
			bottom: 7.1rem;
		}
		100% {
			opacity: 1;
			bottom: 7.2rem;
		}
	}
	@keyframes testAnimation {
		0% {
			opacity: 0;
			bottom: 6.16rem;
		}
		100% {
			opacity: 1;
			bottom: 6.25rem;
		}
	}
	@keyframes buttonAnimation {
		0% {
			opacity: 0;
			bottom: 5.16rem;
		}
		100% {
			opacity: 1;
			bottom: 5.26rem;
		}
	}

	@keyframes launchAnimation {
		0% {
			opacity: 1;
		}

		100% {
			opacity: 0;
		}
	}

	@keyframes launchAnimation2 {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}
}
</style>
