import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import VueRouter from '../router/index.js'

// create an axios instance
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API, // 请求地址

	timeout: 10000, // 请求超时时间
})

// 请求拦截器
service.interceptors.request.use(
	(config) => {
		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)

// 请求 响应
service.interceptors.response.use(
	(response) => {
		const res = response.data
		if (res.code !== 200) {
			if (res.code == 300402 || res.code == 300401) {
				store.dispatch('user/logout').then((res) => {
					VueRouter.push(
						`/login?redirect=${VueRouter.history.current.fullPath}`
					)
				})
			}
			Message({
				message: res.msg || 'Error',
				type: 'error',
				duration: 5 * 1000,
			})
			return Promise.reject(new Error(res.message || 'Error'))
		} else {
			return res.data
		}
	},
	(error) => {
		if (error.message.substr(0, 7) == 'timeout') {
			Message({
				message: '网络波动，请重试',
				type: 'error',
				duration: 5 * 1000,
			})
		} else {
			Message({
				message: error.message,
				type: 'error',
				duration: 5 * 1000,
				
			})
		}
		// VueRouter.push(
		// 	`/view/502`
		// )
		return Promise.reject(error)
	}
)

export default service
