<template>
	<div class="index">
		<webIndexHeader></webIndexHeader>
		<router-view @openLoading="openLoading" @escLoading="escLoading" />
		<indexFoot></indexFoot>
    <feedback></feedback>
		<indexLoading v-show="showLoading"></indexLoading>
	</div>
</template>

<script>
import webIndexHeader from './components/header'
import indexFoot from './components/foot'
import feedback from './components/feedback'
import indexLoading from './loading'
export default {
	name: 'index',
	components: {
		webIndexHeader,
		indexFoot,
		indexLoading,
    feedback,
	},
	data() {
		return {
			showLoading: false,
		}
	},

	methods: {
		openLoading() {
			this.showLoading = true
		},
		escLoading() {
			this.showLoading = false
		},
	},
}
</script>
<style lang="less" scoped></style>
