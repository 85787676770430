<template>
	<div class="foot">

		<img class="logo-div" src="../../../assets/images/LOGO.png" alt="" />
		<div class="test-div">
      桂ICP备2024037825号 | 主办:广西梧州六堡茶股份有限公司 ｜
			承办:广西梧州茶船古道云仓农业科技有限公司 ｜ 联系方式:13217748814
		</div>
		<div class="h-div"></div>
		<!-- <div class="test-div" style="padding-bottom: 0.2rem">
			桂ICP备xxxxxxxxxx号 | ©桂公网安备xxxxxxxxxxxxxx号
		</div> -->
	</div>
</template>

<script>
export default {
	name: 'indexFoot',
	components: {},
	data() {
		return {
		}
	},
	watch: {

	},
	methods: {

	},
}
</script>
<style lang="less" scoped>
.foot {
	background-image: url('../../../assets/images/z-bg.png');
	background-size: 5.4rem 5.4rem;
	background-color: #f3f3f3;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	.logo-div {
		width: 0.5rem;
		height: 0.5rem;
		margin: .4rem 0 .24rem 0;
	}

	.test-div {
		display: flex;
		justify-content: center;
		margin: 0 .32rem;
		font-size: 0.12rem;
		font-family: SCBold;
		color: rgba(72, 72, 72, 0.6);
	}

	.h-div {
		width: 3.5rem;
		height: 0.02rem;
		margin: 0 auto;
		background-color: #d6d6d6;
		margin: 0.14rem auto;
	}
}
</style>
