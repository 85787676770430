<template>
	<div class="foot">
		<div class="navigation-div">
			<div class="industry-div " :class="{ 'select-foot': selectType == '/view/admin/index' }" @click="goRouter('/view/admin/index')">
				<div class="e-div">INDUSTRY</div>
				<div class="c-div">产业发展</div>
			</div>
			<div class="vertical1"></div>
			<div class="tea-enterprises-div" :class="{ 'select-foot': selectType == '/view/admin/teaEnterprises' }" @click="goRouter('/view/admin/teaEnterprises')">
				<div class="e-div">TEA ENTERPRISES</div>
				<div class="c-div">茶企风采</div>
			</div>
			<div class="vertical2"></div>
			<div class="manufacturing-div" :class="{ 'select-foot': selectType == '/view/admin/manufacturing' }" @click="goRouter('/view/admin/manufacturing')">
				<div class="e-div">MANUFACTURING</div>
				<div class="c-div">制作工艺</div>
			</div>
			<div class="vertical3"></div>
			<div class="manufacturing-div" :class="{ 'select-foot': selectType == '/view/admin/intangibleCultural' }" @click="goRouter('/view/admin/intangibleCultural')">
				<div class="e-div">INTANGIBLE CULTURAL</div>
				<div class="c-div">非遗系列</div>
			</div>
			<div class="vertical2"></div>
			<div class="tea-enterprises-div" :class="{ 'select-foot': selectType == '/view/admin/news' }" @click="goRouter('/view/admin/news')">
				<div class="e-div">NEWS</div>
				<div class="c-div">行业新闻</div>
			</div>
			<div class="vertical1"></div>
			<div class="digitalize-div" :class="{ 'select-foot': selectType == '/view/admin/digitalize' }" @click="goRouter('/view/admin/digitalize')">
				<div class="e-div">DIGITALIZE</div>
				<div class="c-div">数字六堡</div>
			</div>
      <div class="vertical1"></div>
			<div class="digitalize-div" :class="{ 'select-foot': selectType == '/view/admin/authention' }" @click="goRouter('/view/admin/authention')">
				<div class="e-div">BRAND AUTHENTION</div>
				<div class="c-div">企业查询</div>
			</div>
		</div>
		<img class="logo-div" src="../assets/images/LOGO.png" alt="" />
		<div class="test-div">

      桂ICP备2024037825号 ｜ 主办:广西梧州六堡茶股份有限公司 ｜
			承办:广西梧州茶船古道云仓农业科技有限公司 ｜ 联系方式:13217748814
		</div>
		<div class="h-div"></div>
		<!-- <div class="test-div" style="padding-bottom: 0.2rem">
			桂ICP备xxxxxxxxxx号 | ©桂公网安备xxxxxxxxxxxxxx号
		</div> -->
	</div>
</template>

<script>
export default {
	name: 'indexFoot',
	components: {},
	data() {
		return {
			selectType: '',
		}
	},
	watch: {
		$route: {
			// $route可以用引号，也可以不用引号  监听的对象
			handler(to, from) {
				this.selectType = to.path
			},
			deep: true, // 深度观察监听 设置为 true
			immediate: true, // 第一次初始化渲染就可以监听到
		},
	},
	methods: {
		goRouter(page) {
			this.$router.push(page)
		},
	},
}
</script>
<style lang="less" scoped>
.foot {
	background-image: url('../assets/images/z-bg.png');
	background-size: 5.4rem 5.4rem;
	background-color: #f3f3f3;
	width: 100%;
	.navigation-div {
		border-top: 0.01rem solid #d6d6d6;
		display: flex;
		width: 14.4rem;
		margin: 0 auto;
		.select-foot{
			div{
				color: #484848 !important;
			}
		}
		
		.industry-div {
			cursor:pointer;
			display: flex;
			margin-top: 0.5rem;
			margin-left: 1.29rem;
			.e-div {
				margin-right: 0.12rem;
				writing-mode: tb-rl;
				font-size: 0.12rem;
				font-family: SCBold;
				font-weight: bold;
				
				color: rgba(72, 72, 72, 0.6);
				line-height: 0.14rem;
			}
			.c-div {
				width: 0.26rem;
				font-size: 0.2rem;
				font-family: SCBold;
				font-weight: bold;
				
				color: rgba(72, 72, 72, 0.6);
				line-height: 0.24rem;
			}
		}
		.vertical1 {
			width: 0.01rem;
			height: 0.3rem;
			margin-left: 0.5rem;
			margin-top: 0.5rem;
			background-color: #d6d6d6;
		}
		.tea-enterprises-div {
			cursor:pointer;
			display: flex;
			margin-top: 0.5rem;
			margin-left: 0.87rem;
			.e-div {
				margin-right: 0.12rem;
				writing-mode: tb-rl;
				font-size: 0.12rem;
				font-family: SCBold;
				font-weight: bold;
				line-height: 0.14rem;
				color: rgba(72, 72, 72, 0.6);
			}
			.c-div {
				width: 0.26rem;
				font-size: 0.2rem;
				font-family: SCBold;
				font-weight: bold;
				line-height: 0.24rem;
				color: rgba(72, 72, 72, 0.6);
			}
		}
		.vertical2 {
			width: 0.01rem;
			height: 0.3rem;
			margin-left: 0.5rem;
			margin-top: 0.5rem;
			background-color: #d6d6d6;
		}
		.manufacturing-div {
			cursor:pointer;
			display: flex;
			margin-top: 0.5rem;
			margin-left: 0.87rem;
			.e-div {
				margin-right: 0.12rem;
				writing-mode: tb-rl;
				font-size: 0.12rem;
				font-family: SCBold;
				font-weight: bold;
				line-height: 0.14rem;
				color: rgba(72, 72, 72, 0.6);
			}
			.c-div {
				width: 0.26rem;
				font-size: 0.2rem;
				font-family: SCBold;
				font-weight: bold;
				line-height: 0.24rem;
				color: rgba(72, 72, 72, 0.6);
			}
		}
		.vertical3 {
			width: 0.01rem;
			height: 0.3rem;
			margin-left: 0.5rem;
			margin-top: 0.5rem;
			background-color: #d6d6d6;
		}
		.digitalize-div {
			cursor:pointer;
			display: flex;
			margin-top: 0.5rem;
			margin-left: 0.87rem;
			.e-div {
				margin-right: 0.12rem;
				writing-mode: tb-rl;
				font-size: 0.12rem;
				font-family: SCBold;
				font-weight: bold;
				color: rgba(72, 72, 72, 0.6);
				line-height: 0.14rem;
			}
			.c-div {
				width: 0.26rem;
				font-size: 0.2rem;
				font-family: SCBold;
				font-weight: bold;
				color: rgba(72, 72, 72, 0.6);
				line-height: 0.24rem;
			}
		}
	}
	.logo-div {
		width: 0.8rem;
		height: 0.8rem;
		margin: 0.99rem 9.2rem 0 9.2rem;
	}
	.test-div {
		display: flex;
		justify-content: center;
		font-size: 0.14rem;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(72, 72, 72, 0.6);
	}
	.h-div {
		width: 14.4rem;
		height: 0.02rem;
		background-color: #d6d6d6;
		margin: 0.14rem auto;
	}
}
</style>
