<template>
    <div class="div_502_div">
        <img src="@/assets/images/502.png" alt="">
    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    components: {},
    watch: {

    },
    methods: {

    },
}
</script>
<style lang="less" scoped>
.div_502_div {
    position: fixed;
    left: 0;
    top: 0;
    background-color: #fff;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    img{
        width: 40%;
    }

}
</style>
