<template>
	<div class="news">
		<div class="body-div">
			<div class="name-div">最新现时动态</div>
			<div class="test-div">NEWS & MOVING</div>
			<div class="block-body">
				<div class="block-div" v-for="item in newsList" :key="item.id">
					<img class="block-img" :src="ossDomain + item.img" alt="" />
					<div class="block-name-div">[ {{ typeName[item.type] }} ]</div>
					<div class="block-test-div">
						{{ item.title }}
					</div>
					<div class="block-info-div">
						<div class="block-info-left" @click="$router.push(`/view/admin/newsInfo?id=${item.id}`)">
							<div>了解更多</div>
							<img src="../../assets/images/icon-right.png" alt="" />
						</div>
						<div class="block-info-right">{{ formatDate(item.releaseTime) }}</div>
					</div>
					<img class="block-bg-img" src="../../assets/images/new-block-bg.png" alt="" />
				</div>
			</div>
			<div class="page-div">
				<img src="../../assets/images/news-left-icon.png" @click="leftEvent" alt="">
				<div class="page-page-div">
					{{ page.page }}
				</div>
				<div>
					-
				</div>
				<div class="page-tatol-div">
					{{ totalPages }}
				</div>
				<img src="../../assets/images/news-right-icon.png" @click="rightEvent" alt="">
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'news',
	components: {},
	data() {
		return {
			newsList: [],
			page: {
				page: 1,
				limit: 6,
			},
			total: 0,
			totalPages: 0,
			typeName: {
				1: '公告公示',
				2: '招标信息',
				3: '政策解读',
				4: '法律法规',
				5: '产业报告',
				6: '产业要闻',
				7: '市场资讯'
			},

		}
	},
	computed: {
		...mapGetters(['ossDomain']),
	},
	created() {
		this.$emit('openLoading')
		this.getPortalMessageNoticeList()
	},
	methods: {
		leftEvent() {
			if (this.page.page <= 1) {
				return
			}
			this.page.page = this.page.page - 1
			this.getPortalMessageNoticeList()
		},
		rightEvent() {
			if (this.page.page * this.page.limit >= this.total) {
				return
			}
			this.page.page = this.page.page + 1
			this.getPortalMessageNoticeList()
		},
		getPortalMessageNoticeList() {
			this.getPortalMessageNoticeListApi(this.page).then((res) => {
				this.newsList = res.rows
				this.total = res.total
				if ((res.total * 1) < 7) {
					this.totalPages = 1
				} else {
					this.totalPages = (res.total * 1) % 6 == 0 ? (res.total * 1) / 6 : (((res.total * 1) - (res.total * 1) % 6) / 6 + 1)
				}
				this.$emit('escLoading')
			})
		},
	},
}
</script>
<style lang="less" scoped>
.news {
	width: 100%;
	background-color: #f3f3f3;
	background-image: url('../../assets/images/z-bg.png');
	background-size: 5.4rem 5.4rem;

	.body-div {
		width: 14.4rem;
		padding: 0 2.4rem 1.21rem 2.4rem;

		.name-div {
			display: flex;
			justify-content: center;
			font-size: 0.4rem;
			font-family: SCBold;
			font-weight: bold;
			color: #484848;
			padding-top: 1.5rem;
		}

		.test-div {
			display: flex;
			justify-content: center;
			font-size: 0.14rem;
			font-family: SCBold;
			font-weight: bold;
			color: #484848;
			margin-top: 0.1rem;
			margin-bottom: 0.6rem;
		}

		.block-body {
			width: 14.4rem;
			margin: 0 auto;
			display: flex;
			flex-flow: row wrap;
			align-content: flex-start;
		}

		.block-div {
			margin: 0.3rem 0.1rem;
			position: relative;
			font-size: 0;
			width: 4.4rem;
			background: #ffffff;
			border-radius: 0.06rem;

			.block-bg-img {
				position: absolute;
				right: 0;
				bottom: 0;
				width: 1.66rem;
				height: 1.66rem;
			}

			.block-img {
				width: 4.4rem;
				height: 2.4rem;
				border-radius: 0.06rem 0.06rem 0 0;
			}

			.block-name-div {
				font-size: 0.2rem;
				font-family: SCBold;
				font-weight: bold;
				color: #484848;
				line-height: 0.2rem;
				letter-spacing: 0.02rem;
				margin: 0.24rem 0.4rem 0.2rem 0.4rem;
			}

			.block-test-div {
				position: relative;
				z-index: 2;
				font-size: 0.14rem;
				font-family: SCBold;
				font-weight: bold;
				color: #484848;
				margin: 0 0.4rem 0.25rem 0.4rem;
			}

			.block-info-div {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0 0.4rem 0.2rem 0.4rem;

				.block-info-left {
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 1.15rem;
					padding: 0.05rem 0;
					border-bottom: 0.02rem solid #484848;

					div {
						font-size: 0.12rem;
						font-family: SCBold;
						font-weight: bold;
						color: #484848;
						line-height: 0.12rem;
						letter-spacing: 0.01rem;
					}

					img {
						width: 0.22rem;
						height: 0.22rem;
					}
				}

				.block-info-right {
					position: relative;
					z-index: 2;
					font-size: 0.12rem;
					font-family: SCBold;
					font-weight: bold;
					color: #999999;
					line-height: 0.12rem;
					letter-spacing: 0.01rem;
				}
			}
		}

		.page-div {
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				cursor: pointer;
				width: .14rem;
				height: .14rem;
			}

			div {
				font-size: .14rem;
				font-family: SCBold;
				font-weight: bold;
				color: #484848;
				letter-spacing: 1px;
			}

			.page-page-div {
				margin-left: .24rem;
				margin-right: .1rem;
			}

			.page-tatol-div {
				margin-left: .1rem;
				margin-right: .24rem;
			}
		}
	}
}
</style>
