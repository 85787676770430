<template>
	<div class="header">
		<div class="header-div">
			<img
				class="bg-img"
				:src="
					require(`../assets/images/${
						imgName == 'newsInfo' ? 'news' : imgName
					}Header.png`)
				"
				alt=""
			/>
			<img class="logo-img" src="../assets/images/LOGO.png" alt="" />
			<img class="shop-img" src="../assets/images/shopping.png" alt="" @click="openShop" />
			<img class="big-logo-img" src="../assets/images/bigLogo.png" alt="" />
		</div>
		<div class="navigation-div">
			<div class="industry-div select-div" @click="goRouter('/view/admin/index')">
				<div
					class="e-div"
					:class="{ 'e-div-select': selectType == '/view/admin/index' }"
				>
					INDUSTRY
				</div>
				<div
					class="c-div"
					:class="{ 'e-div-select': selectType == '/view/admin/index' }"
				>
					产业发展
				</div>
				<div
					class="select-navigation"
					:class="{ 'navigation-h': selectType == '/view/admin/index' }"
				></div>
			</div>
			<div class="vertical1"></div>
			<div
				class="tea-enterprises-div select-div"
				@click="goRouter('/view/admin/teaEnterprises')"
			>
				<div
					class="e-div"
					:class="{ 'e-div-select': selectType == '/view/admin/teaEnterprises' }"
				>
					TEA ENTERPRISES
				</div>
				<div
					class="c-div"
					:class="{ 'e-div-select': selectType == '/view/admin/teaEnterprises' }"
				>
					茶企风采
				</div>
				<div
					class="select-navigation"
					:class="{ 'navigation-h': selectType == '/view/admin/teaEnterprises' }"
				></div>
			</div>
			<div class="vertical2"></div>
			<div
				class="manufacturing-div select-div"
				@click="goRouter('/view/admin/manufacturing')"
			>
				<div
					class="e-div"
					:class="{ 'e-div-select': selectType == '/view/admin/manufacturing' }"
				>
					MANUFACTURING
				</div>
				<div
					class="c-div"
					:class="{ 'e-div-select': selectType == '/view/admin/manufacturing' }"
				>
					制作工艺
				</div>
				<div
					class="select-navigation"
					:class="{ 'navigation-h': selectType == '/view/admin/manufacturing' }"
				></div>
			</div>
			<div class="vertical3"></div>
			<div
				class="manufacturing-div select-div"
				@click="goRouter('/view/admin/intangibleCultural')"
			>
				<div
					class="e-div"
					:class="{ 'e-div-select': selectType == '/view/admin/intangibleCultural' }"
				>
					INTANGIBLE CULTURAL
				</div>
				<div
					class="c-div"
					:class="{ 'e-div-select': selectType == '/view/admin/intangibleCultural' }"
				>
					非遗系列
				</div>
				<div
					class="select-navigation"
					:class="{ 'navigation-h': selectType == '/view/admin/intangibleCultural' }"
				></div>
			</div>
			<div class="vertical2"></div>
			<div
				class="tea-enterprises-div select-div"
				@click="goRouter('/view/admin/news')"
			>
				<div
					class="e-div"
					:class="{ 'e-div-select': selectType == '/view/admin/news' }"
				>
					NEWS
				</div>
				<div
					class="c-div"
					:class="{ 'e-div-select': selectType == '/view/admin/news' }"
				>
					行业新闻
				</div>
				<div
					class="select-navigation"
					:class="{ 'navigation-h': selectType == '/view/admin/news' }"
				></div>
			</div>
			<div class="vertical1"></div>
			<div
				class="digitalize-div select-div"
				@click="goRouter('/view/admin/digitalize')"
			>
				<div
					class="e-div"
					:class="{ 'e-div-select': selectType == '/view/admin/digitalize' }"
				>
					DIGITALIZE
				</div>
				<div
					class="c-div"
					:class="{ 'e-div-select': selectType == '/view/admin/digitalize' }"
				>
					数字六堡
				</div>
				<div
					class="select-navigation"
					:class="{ 'navigation-h': selectType == '/view/admin/digitalize' }"
				></div>
			</div>
			<div class="vertical1"></div>
      <div
				class="digitalize-div select-div"
				@click="goRouter('/view/admin/authention')"
			>
				<div
					class="e-div"
					:class="{ 'e-div-select': selectType == '/view/admin/authention' }"
				>
					BRAND AUTHENTION
				</div>
				<div
					class="c-div"
					:class="{ 'e-div-select': selectType == '/view/admin/authention' }"
				>
					企业查询
				</div>
				<div
					class="select-navigation"
					:class="{ 'navigation-h': selectType == '/view/admin/authention' }"
				></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'indexHeader',
	data() {
		return {
			selectType: '',
			imgName: '',
		}
	},
	components: {},
	watch: {
		$route: {
			// $route可以用引号，也可以不用引号  监听的对象
			handler(to, from) {
				this.imgName = to.name
				this.selectType = to.path
			},
			deep: true, // 深度观察监听 设置为 true
			immediate: true, // 第一次初始化渲染就可以监听到
		},
	},
	methods: {
		openShop(){
			window.open('https://www.liupaoinc.com/wuzhou-mall-pc-web/#/')
		},
		goRouter(page) {
			this.$router.push(page)
		},
	},
}
</script>
<style lang="less" scoped>
.header {
	background-image: url('../assets/images/z-bg.png');
	background-size: 5.4rem 5.4rem;
	background-color: #f3f3f3;
	width: 100%;
	.header-div {
		position: relative;
		width: 100%;
		height: 7rem;
		.bg-img {
			position: absolute;
			width: 100%;
		}
		.big-logo-img {
			position: absolute;
			width: 4rem;
			height: 4rem;
			top: 1.5rem;
			left: 7.6rem;
		}
		.logo-img {
			position: absolute;
			z-index: 2;
			left: 1rem;
			top: 0.3rem;
			width: 0.8rem;
		}

		.shop-img {
			cursor:pointer;
			position: absolute;
			z-index: 2;
			right: 0.6rem;
			top: 0.4rem;
			width: 0.64rem;
		}
	}
	.navigation-div {
		display: flex;
		width: 14.4rem;
		height: 3.2rem;
		margin: 0 auto;
		border-bottom: 0.01px solid #d6d6d6;
		.e-div-select {
			color: #484848 !important;
		}
		.industry-div {
			cursor: pointer;
			position: relative;
			display: flex;
			margin-top: 0.3rem;
			margin-left: 0.29rem;
			height: 2.5rem;
			.e-div {
				margin-right: 0.12rem;
				writing-mode: tb-rl;
				font-size: 0.12rem;
				font-family: SCBold;
				font-weight: bold;
				color: rgba(72, 72, 72, 0.6);
				line-height: 0.14rem;
			}
			.c-div {
				width: 0.26rem;
				font-size: 0.2rem;
				font-family: SCBold;
				font-weight: bold;
				color: rgba(72, 72, 72, 0.6);
				line-height: 0.24rem;
			}
		}
		.vertical1 {
			width: 0.01rem;
			height: 0.3rem;
			margin-left: 0.87rem;
			margin-top: 0.3rem;
			background-color: #d6d6d6;
		}
		.tea-enterprises-div {
			cursor: pointer;
			position: relative;
			display: flex;
			margin-top: 0.4rem;
			margin-left: 0.87rem;
			height: 2.5rem;

			.e-div {
				margin-right: 0.12rem;
				writing-mode: tb-rl;
				font-size: 0.12rem;
				font-family: SCBold;
				font-weight: bold;
				line-height: 0.14rem;
				color: rgba(72, 72, 72, 0.6);
			}
			.c-div {
				width: 0.26rem;
				font-size: 0.2rem;
				font-family: SCBold;
				font-weight: bold;
				line-height: 0.24rem;
				color: rgba(72, 72, 72, 0.6);
			}
		}
		.vertical2 {
			width: 0.01rem;
			height: 0.3rem;
			margin-left: 0.87rem;
			margin-top: 0.4rem;
			background-color: #d6d6d6;
		}
		.manufacturing-div {
			cursor: pointer;
			position: relative;
			display: flex;
			margin-top: 0.5rem;
			margin-left: 0.87rem;
			height: 2.5rem;

			.e-div {
				margin-right: 0.12rem;
				writing-mode: tb-rl;
				font-size: 0.12rem;
				font-family: SCBold;
				font-weight: bold;
				line-height: 0.14rem;
				color: rgba(72, 72, 72, 0.6);
			}
			.c-div {
				width: 0.26rem;
				font-size: 0.2rem;
				font-family: SCBold;
				font-weight: bold;
				line-height: 0.24rem;
				color: rgba(72, 72, 72, 0.6);
			}
		}
		.vertical3 {
			width: 0.01rem;
			height: 0.3rem;
			margin-left: 0.87rem;
			margin-top: 0.5rem;
			background-color: #d6d6d6;
		}
		.digitalize-div {
			cursor: pointer;
			position: relative;
			display: flex;
			margin-top: 0.3rem;
			margin-left: 0.87rem;
			height: 2.5rem;

			.e-div {
				margin-right: 0.12rem;
				writing-mode: tb-rl;
				font-size: 0.12rem;
				font-family: SCBold;
				font-weight: bold;
				color: rgba(72, 72, 72, 0.6);
				line-height: 0.14rem;
			}
			.c-div {
				width: 0.26rem;
				font-size: 0.2rem;
				font-family: SCBold;
				font-weight: bold;
				color: rgba(72, 72, 72, 0.6);
				line-height: 0.24rem;
			}
		}
		.select-navigation {
			position: absolute;
			bottom: 0;
			width: 0;
			height: 0.04rem;
			background-color: #484848;
			border-radius: 0.02rem;
			margin: 0 0.11rem;
			transition: 0.5s;
		}
		.navigation-h {
			width: 0.3rem;
		}
		.select-div div {
			transition: 0.5s;
		}
		.select-div:hover {
			div {
				color: #484848;
				margin-top: -0.1rem;
			}
		}
	}
}
</style>
