import Vue from 'vue'
import VueRouter from 'vue-router'
import adminLaunchView from '../views/admin/launch.vue'
import webLaunchView from '../views/web/launch.vue'
import indexView from '../views/admin/index.vue'
import webIndexView from '../views/web/index.vue'
import teaEnterprises from '../views/admin/teaEnterprises.vue'
import webTeaEnterprises from '../views/web/teaEnterprises.vue'
import manufacturing from '../views/admin/manufacturing.vue'
import webManufacturing from '../views/web/manufacturing.vue'
import intangibleCultural from '../views/admin/intangibleCultural.vue'
import webIntangibleCultural from '../views/web/intangibleCultural.vue'
import viewView from '../views/admin/view.vue'
import webViewView from '../views/web/view.vue'
import news from '../views/admin/news.vue'
import webNews from '../views/web/news.vue'
import newsInfo from '../views/admin/newsInfo.vue'
import webNewsInfo from '../views/web/newsInfo.vue'
import digitalize from '../views/admin/digitalize.vue'
import webDigitalize from '../views/web/digitalize.vue'
import errorView from '../views/502.vue'
import excess from '../views/excess.vue'
import authention from '../views/admin/authention.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'excess',
		component: excess,
	},
	{
		path: '/admin/launch',
		name: 'launch',
		component: adminLaunchView,
	},
	{
		path: '/web/launch',
		name: 'webLaunch',
		component: webLaunchView,
	},
	{
		path: '/view/admin',
		component: viewView,
		name: 'view',
		redirect: '/view/admin/index',
		children: [
			{
				path: 'index',
				name: 'index',
				component: indexView,
			},
			{
				path: 'teaEnterprises',
				name: 'teaEnterprises',
				component: teaEnterprises,
			},
			{
				path: 'manufacturing',
				name: 'manufacturing',
				component: manufacturing,
			},
			{
				path: 'intangibleCultural',
				name: 'intangibleCultural',
				component: intangibleCultural,
			},
			{
				path: 'news',
				name: 'news',
				component: news,
			},
			{
				path: 'newsInfo',
				name: 'newsInfo',
				component: newsInfo,
			},
			{
				path: 'digitalize',
				name: 'digitalize',
				component: digitalize,
			},
			{
				path: '502',
				name: '502',
				component: errorView,
			},
			{
				path: 'authention',
				name: 'authention',
				component: authention,
			},
		],
	},
	{
		path: '/view/web',
		component: webViewView,
		name: 'view',
		redirect: '/view/web/index',
		children: [
			{
				path: 'index',
				name: 'index',
				meta:{
					cName: '产业发展',
					eName: 'INDUSTRY',
				},
				
				component: webIndexView,
			},
			{
				path: 'teaEnterprises',
				name: 'teaEnterprises',
				meta:{
					cName: '茶企风采',
					eName: 'TEA ENTERPRISES',
				},
				
				component: webTeaEnterprises,
			},
			{
				path: 'manufacturing',
				name: 'manufacturing',
				meta:{
					cName: '制作工艺',
					eName: 'MANUFACTURING',
				},
				component: webManufacturing,
			},
			{
				path: 'intangibleCultural',
				name: 'intangibleCultural',
				meta:{
					cName: '非遗系列',
					eName: 'INTANGIBLE CULTURAL',
				},
				component: webIntangibleCultural,
			},
			{
				path: 'news',
				name: 'news',
				meta:{
					cName: '行业新闻',
					eName: 'NEWS',
				},
				
				component: webNews,
			},
			{
				path: 'newsInfo',
				name: 'newsInfo',
				meta:{
					cName: '行业新闻',
					eName: 'NEWS',
				},
				component: webNewsInfo,
			},
			{
				path: 'digitalize',
				name: 'digitalize',
				meta:{
					cName: '数字六堡',
				eName: 'DIGITALIZE',
				},
				
				component: webDigitalize,
			},
			{
				path: '502',
				name: '502',
				meta:{
					cName: '502',
					eName: 'ERROR',
				},
				
				component: errorView,
			},
		],
	},
]

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}
export default router
