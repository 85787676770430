<template>
	<div class="manufacturing">
		<div class="info-div">
			<div class="name-div">千百年传承的制茶技艺</div>
			<div class="test-div">TEA MAKING TECHNIQUES</div>
			<div class="block-div">
				<img
					class="block-img"
					style="width: 7.41rem"
					src="../../assets/images/manufacturing-img1.png"
					alt=""
				/>
				<div class="block-info" style="margin-top: 1.3rem">
					<div class="block-name">一、摘青</div>
					<div class="block-test" style="width: 3.8rem">
						六堡茶的制作是采用当地的大叶种茶树的鲜叶作为原料，采摘标准是成熟新梢的一芽二三叶或一芽三四叶。
					</div>
				</div>
			</div>
			<div class="block-div" style="display: flex; justify-content: flex-end">
				<div class="block-info" style="margin-top: 1.1rem">
					<div
						class="block-name"
						style="display: flex; justify-content: flex-end"
					>
						二、杀青
					</div>
					<div class="block-test" style="width: 3.8rem">
						杀青的主要目的是通过高温破坏和钝化鲜叶中的氧化酶活性，抑制鲜叶中茶多酚等的酶促氧化，蒸发鲜叶部分水分，使茶叶变软，同时去除青臭味，促进良好香气的形成。
					</div>
				</div>
				<img
					class="block-img"
					style="width: 8.75rem"
					src="../../assets/images/manufacturing-img2.png"
					alt=""
				/>
			</div>
			<div class="block-div" style="margin-top: 0.62rem">
				<img
					class="block-img"
					style="width: 7.21rem"
					src="../../assets/images/manufacturing-img3.png"
					alt=""
				/>
				<div class="block-info" style="margin-top: 1.3rem">
					<div class="block-name">三、揉捻</div>
					<div class="block-test" style="width: 3.8rem">
						六堡茶的揉捻主要是为了做形，手法上讲求“先轻后重”，让茶叶细胞壁破裂，使茶汁溢出，以增加茶汤浓度，但又不会太充分破裂，以免影响耐泡度，同时使之紧结成形。
					</div>
				</div>
			</div>
			<div
				class="block-div"
				style="display: flex; justify-content: flex-end; margin-top: 1.49rem"
			>
				<div class="block-info" style="margin-top: 0.09rem">
					<div
						class="block-name"
						style="display: flex; justify-content: flex-end"
					>
						四、发酵
					</div>
					<div class="block-test" style="width: 3.8rem">
						发酵是六堡茶形成其独特品质的关键工艺，其包括“堆闷”“渥堆”等几道工序，目的是让茶叶内的多酚类物质在湿热、微生物和酶促作用下发生一系列的化学变化，从而形成六堡茶“外形黑润、汤色红浓明亮、香气纯正、滋味醇和”的品质特征。
					</div>
				</div>
				<img
					class="block-img"
					style="width: 6.49rem"
					src="../../assets/images/manufacturing-img4.png"
					alt=""
				/>
			</div>
			<div class="block-div" style="margin-top: 1.18rem">
				<img
					class="block-img"
					style="width: 6.4rem"
					src="../../assets/images/manufacturing-img5.png"
					alt=""
				/>
				<div class="block-info" style="margin-top: 1.85rem">
					<div class="block-name">五、陈化</div>
					<div class="block-test" style="width: 3.8rem">
						将茶叶置于清洁、阴凉、通风、无异杂味的环境内陈化，通过微生物发酵使茶具有越陈越香的特点。
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'manufacturing',
	components: {},
}
</script>
<style lang="less" scoped>
.manufacturing {
	background-image: url('../../assets/images/z-bg.png');
	background-size: 5.4rem 5.4rem;
	width: 100%;
	background-color: #f3f3f3;
	.info-div {
		width: 14.4rem;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 2.13rem;
		.name-div {
			font-size: 0.4rem;
			font-family: SCBold;
			font-weight: bold;
			color: #484848;
			margin-top: 1.5rem;
		}
		.test-div {
			font-size: 0.14rem;
			font-family: SCBold;
			font-weight: bold;
			color: #484848;
			margin-top: 0.1rem;
			margin-bottom: 0.6rem;
		}
		.block-div {
			width: 100%;
			margin-top: 0.4rem;
			display: flex;
			.block-info {
				.block-name {
					font-size: 0.2rem;
					font-family: SCBold;
					font-weight: bold;
					color: #484848;
				}
				.block-test {
					font-size: 0.14rem;
					font-family: SCBold;
					font-weight: bold;
					color: #484848;
					margin-top: 0.4rem;
				}
			}
		}
	}
}
</style>
