<template>
	<div class="digitalize">
		<div class="num-div">
			<div class="name-div">数字化茶园</div>
			<div class="test-div">NEWS & MOVING</div>
			<div class="info-div">
				以物联感知为基础，采集茶园数据，依托5G传输技术、大数据分析技术、信息系统集成技术，对茶园进行全方面的数字化管理
			</div>
			<div class="img-info-div">
				<div class="img-info-ny info-test">
					<div class="img-info-name">农业气象</div>
					<div class="img-info-test">
						空气温湿度、大气压力、光照强度、风速风向 降雨量、CO2浓度、PM2.5
					</div>
				</div>
				<div class="img-info-tr info-test">
					<div class="img-info-name">土壤墒情</div>
					<div class="img-info-test">
						土壤温湿度、土壤导电率、土壤PH值、土壤盐度
					</div>
				</div>
				<div class="img-info-cq info-test">
					<div class="img-info-name">虫情防治</div>
					<div class="img-info-test">
						虫情诱杀、虫情测报、虫情分析、虫情统计
					</div>
				</div>
				<div class="img-info-mq info-test">
					<div class="img-info-name">苗情监测</div>
					<div class="img-info-test">种苗管理、长势分析</div>
				</div>
			</div>
		</div>
		<div class="num-div" style="background-color: #fff">
			<div class="name-div">数字化仓库</div>
			<div class="test-div">NEWS & MOVING</div>
			<div class="info-div">
				数字化仓储主要是以物联网的大数据、为核心技术,通过可编程无线扫码,自动采集仓库到货检验、入库、出库、调拨、班次、库存盘点等各个操作环节的数据,
				确保企业能够及时准确地掌握库存的真实数据,
				高效地跟踪管理客户订单、采购订单和仓库信息,从而更有效地提高仓库管理的效率及效益。
			</div>
			<div class="img-info-div">
				<div class="img-info-ck info-test">
					<div class="img-info-name2">智能仓库</div>
					<div class="img-info-test">
						智能仓储是物流过程的一个环节，智能仓储的应用，保证了货物仓库管理各个环节数据输入的速度和准确性，确保企业及时准确地掌握库存的真实数据，合理保持和控制企业库存。通过科学的编码，还可方便地对库存货物的批次、保质期等进行管理。利用SNHGES系统的库位管理功能，更可以及时掌握所有库存货物当前所在位置，有利于提高仓库管理的工作效率。
					</div>
				</div>
				<div class="img-info-wl info-test">
					<div class="img-info-name2">物联感知</div>
					<div class="img-info-test">
						物联感知层是物联网的基础，是联系物理世界与信息世界的重要纽带。感知层是由大量的具有感知、通信、识别能力的智能物体与感知网络组成。目前的主要技术有：RFID技术、二维码技术、Zig-Bee技术和蓝牙技术。
					</div>
				</div>
				<div class="img-info-crk info-test">
					<div class="img-info-name2">出入库管理</div>
					<div class="img-info-test">
						利用一物一码技术对仓库货物各环节实施全过程控制管理的系统，可对仓库货物进行入库、出库、货位、批次、保质期等实现一物一码标签序列号管理，对整个收货、发货、补货、集货、送货等每个环节的规范化作业
						, 还可以根据用户诉求制作多种合理的统计报表。
					</div>
				</div>
				<div class="img-info-yj info-test">
					<div class="img-info-name2">预警告警</div>
					<div class="img-info-test">对农业存在预警异常信息，进行预警告警</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'digitalize',
	components: {},
	data() {
		return {}
	},
	methods: {},
}
</script>
<style lang="less" scoped>
.digitalize {
	width: 100%;
	background-color: #f3f3f3;
	background-image: url('../../assets/images/z-bg.png');
	background-size: 5.4rem 5.4rem;
	.name-div {
		display: flex;
		justify-content: center;
		font-size: 0.2rem;
		font-family: SCBold;
		font-weight: bold;
		color: #484848;
		padding-top: .5rem;
	}
	.test-div {
		display: flex;
		justify-content: center;
		font-size: 0.12rem;
		font-family: SCBold;
		font-weight: bold;
		color: #484848;
		margin-top: 0.1rem;
	}
	.info-div {
		margin: 0 .45rem;
		display: flex;
		justify-content: center;
		font-size: 0.12rem;
		font-family: SCBold;
		font-weight: bold;
		color: #484848;
		margin-top: .3rem;
	}
	.num-div {
		width: 100%;
		.img-info-div {
			padding: 0.1rem .35rem;
			.info-test {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 3.2rem;
				height: 2.5rem;
				background-size: 100% 100%;
			}
			.img-info-ny {
				background-image: url('../../assets/images/digitalize-ny.png');
			}
			.img-info-tr {
				background-image: url('../../assets/images/digitalize-tr.png');
			}
			.img-info-cq {
				background-image: url('../../assets/images/digitalize-cq.png');
			}
			.img-info-mq {
				background-image: url('../../assets/images/digitalize-mq.png');
			}
			.img-info-ck {
				background-image: url('../../assets/images/digitalize-ck.png');
			}
			.img-info-wl {
				background-image: url('../../assets/images/digitalize-wl.png');
			}
			.img-info-crk {
				background-image: url('../../assets/images/digitalize-crk.png');
			}
			.img-info-yj {
				background-image: url('../../assets/images/digitalize-yj.png');
			}

			.img-info-name {
				display: flex;
				justify-content: center;
				font-size: 0.15rem;
				font-family: SCBold;
				font-weight: bold;
				color: #ffffff;
				margin: .2rem 0 0.2rem 0;
			}
			.img-info-name2 {
				display: flex;
				justify-content: center;
				font-size: 0.15rem;
				font-family: SCBold;
				font-weight: bold;
				color: #ffffff;
				margin: .2rem 0 0.2rem 0;
			}
			.img-info-test {
				display: flex;
				justify-content: center;
				width: 2.91rem;
				font-size: 0.12rem;
				font-family: SCBold;
				font-weight: bold;
				color: #ffffff;
			}
		}
	}
}
</style>
