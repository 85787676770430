<template>
	<div>
		<div class="header">
			<img class="header-logo" src="../../../assets/images/LOGO.png" alt="">
			<div class="header-name-div">
				<div>{{ cName }}</div>
				<div>{{ eName }}</div>
			</div>
			<div class="operate-div" @click="menuType = !menuType">
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
		<div class="menu-div " :class="{ 'menu-div-s': !menuType }">
			<div class="menu-name-div " @click="goRouter('/view/web/index')" :class="{ 'menu-name-div-x': selectType == '/view/web/index' }">
				<div>产业发展</div>
				<div>industry</div>
			</div>
			<div class="menu-name-div" @click="goRouter('/view/web/teaEnterprises')" :class="{ 'menu-name-div-x': selectType == '/view/web/teaEnterprises' }">
				<div>茶企风采</div>
				<div>tea enterprises</div>
			</div>
			<div class="menu-name-div" @click="goRouter('/view/web/manufacturing')" :class="{ 'menu-name-div-x': selectType == '/view/web/manufacturing' }">
				<div>制作工艺</div>
				<div>Manufacturing</div>
			</div>
			<div class="menu-name-div" @click="goRouter('/view/web/intangibleCultural')" :class="{ 'menu-name-div-x': selectType == '/view/web/intangibleCultural' }">
				<div>非遗系列</div>
				<div>intangible cultural</div>
			</div>
			<div class="menu-name-div" @click="goRouter('/view/web/news')" :class="{ 'menu-name-div-x': selectType == '/view/web/news' }">
				<div>行业新闻</div>
				<div>NEWS</div>
			</div>
			<div class="menu-name-div" @click="goRouter('/view/web/digitalize')" :class="{ 'menu-name-div-x': selectType == '/view/web/digitalize' }">
				<div>数字六堡</div>
				<div>digitalize</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'webIndexHeader',
	data() {
		return {
			menuType: false,
			selectType:'',
			cName:'',
			eName:''
		}
	},
	components: {},
	watch: {
		$route: {
			// $route可以用引号，也可以不用引号  监听的对象
			handler(to, from) {
				this.cName = to.meta.cName
				this.eName = to.meta.eName
				this.selectType = to.path
				this.menuType = false
				console.log(to);
			},
			deep: true, // 深度观察监听 设置为 true
			immediate: true, // 第一次初始化渲染就可以监听到
		},
	},
	methods: {

		goRouter(page) {
			this.$router.push(page)
		},
	},
}
</script>
<style lang="less" scoped>
.header {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: .6rem;
	background-color: #fff;
	z-index: 1000;

	.header-logo {
		width: .4rem;
		height: .4rem;
		margin-left: .3rem;
	}

	.header-name-div {
		
		div:nth-child(1) {
			text-align: center;
			font-size: .14rem;
			font-family: SCBold;
			font-weight: bold;
			color: #484848;
		}

		div:nth-child(2) {
			text-align: center;
			font-size: .12rem;
			font-family: SCBold;
			font-weight: bold;
			color: #484848;
		}
	}

	.operate-div {
		margin-right: .3rem;

		div {
			width: .23rem;
			height: .03rem;
			background: #484848;
			border-radius: .02rem;
			margin-bottom: .04rem;
		}

	}


}

.menu-div {
	width: 3.9rem;
	top: .6rem;
	position: fixed;
	background-color: #fff;
	z-index: 999;
	transition: all 0.5s;

	.menu-name-div {
		text-align: center;
		margin: .1rem 0;
		font-family: SCBold;
		font-weight: bold;
		color: rgba(72, 72, 72, 0.6);

		div:nth-child(1) {
			font-size: .14rem;

		}

		div:nth-child(2) {
			font-size: .12rem;

		}
	}
	.menu-name-div-x{
		color: #484848 !important;

	}

}

.menu-div-s {
	top: -4rem !important;
}
</style>
