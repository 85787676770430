<template>
	<div class="index">
		<div class="banner-div" v-if="courseList.length != 0">
			<img class="left-div" @click="leftEvent" src="../../assets/images/icon-left.png" alt="" />
			<div class="info-div">
				<div class="name-div">发展历程</div>
				<div class="test-div">CHARACTERISTIC</div>
				<div v-for="(item, index) in courseList" :key="item.id">
					<div class="info-info" v-show="index == bannerIndex">
						<img class="info-img" :src="ossDomain + item.img.split(',')[0]" alt="" />
						<div class="h-div" v-show="index == bannerIndex">
							{{ item.title }}
						</div>
						<div class="testear-div" v-show="index == bannerIndex">
							{{ item.subtitle }}
						</div>
						<div class="info-test-div" v-html="item.details"></div>
					</div>
				</div>
				<div class="t-div">
					<div class="for-div" v-for="(item, index) in courseList" :key="item.id">
						<div class="q-div" :data-star="item.nodeTime" @click="bannerIndex = index">
							<div class="nq-div" v-show="bannerIndex == index"></div>
						</div>
						<div class="t-h-div" v-if="index != courseList.length - 1"></div>
					</div>
				</div>
			</div>
			<img class="right-div" @click="rightEvent" src="../../assets/images/icon-right.png" alt="" />
		</div>
		<div class="show-div">
			<div class="name-div">产品特点</div>
			<div class="test-div">CHARACTERISTIC</div>
			<div class="info-div">
				<div>
					<img src="../../assets/images/characteristic1.png" alt="" />
				</div>
				<div>
					<div class="info-name-div">红</div>
					<div class="info-test-div">外型色泽黑褐</div>
				</div>
				<div>
					<img src="../../assets/images/characteristic2.png" alt="" />
				</div>
				<div>
					<div class="info-name-div">醇</div>
					<div class="info-test-div">香醇厚重、爽口回甘</div>
				</div>
				<div>
					<img src="../../assets/images/characteristic3.png" alt="" />
				</div>
				<div>
					<div class="info-name-div">陈</div>
					<div class="info-test-div">耐予久藏、越陈越香</div>
				</div>
				<div>
					<img src="../../assets/images/characteristic4.png" alt="" />
				</div>
				<div>
					<div class="info-name-div">浓</div>
					<div class="info-test-div">汤色红浓明亮</div>
				</div>
				<div>
					<img src="../../assets/images/characteristic5.png" alt="" />
				</div>
			</div>
		</div>
		<div class="goods-div">
			<div class="name-div">热门产品</div>
			<div class="test-div">characteristic</div>
			<div class="info-div">
				<div class="info-block1" v-for="item in goodsList" :key="item.id">
					<div class="info-img1">
						<img class="img-block1" src="../../assets/images/goods-bg.png" alt="" />
						<img class="img-block-goods" @click="openGoods(item)" :src="ossDomain + item.img.split(',')[0]" alt="" />
					</div>
					<div class="info-name1">{{ item.name }}</div>
					<div class="info-h"></div>
				</div>
        <div class="info-block1" v-if="goodsList.length % 3 == 2">
					<div class="info-img1">
						<img class="img-block1" />
						<img class="img-block-goods" />
					</div>
					<div class="info-name1"></div>
					<!-- <div class="info-h"></div> -->
				</div>
			</div>
			<div class="router-div" @click="openWindow">
				进入商城
				<img src="../../assets/images/icon-right.png" alt="" />
			</div>
		</div>
		<div class="goods-info-body" v-if="showGoods" @click="showGoods = false">
			<div class="goods-info" @click.stop>
				<div class="goods-img">
					<img :src="ossDomain + goodsInfo.img.split(',')[0]" alt="" />
				</div>
				<div class="goods-test">
					<div class="info-title">商品名</div>
					<div class="info-title1">{{ goodsInfo.name }}</div>
					<!-- <div class="info-title">店铺</div>
					<div class="info-title2">神农智联</div> -->
					<div class="info-title">商品类目</div>
					<div class="info-title2">{{ goodsInfo.typeParentName || '' }} - {{ goodsInfo.typeName || '' }}</div>
					<div class="info-title" v-if="goodsInfo.label">商品描述</div>
					<div class="info-title2">
						{{ goodsInfo.label }}
					</div>
					<div class="info-title">商品价格</div>
					<div class="info-title3">¥{{ goodsInfo.price }}</div>
					<img class="esc-img" @click="showGoods = false" src="../../assets/images/esc-icon.png" alt="" />
					<div class="qr-code-img" v-loading="imgLoading">
						<img :src="goodsInfo.qrCode" alt="" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'index',
	components: {},
	data() {
		return {
			bannerIndex: 0,
			showGoods: false,
			imgLoading: false,
			courseList: [],
			goodsList: [],
			goodsInfo: {},
		}
	},
	computed: {
		...mapGetters(['ossDomain']),
	},
	created() {
		this.$emit('openLoading')
	},
	mounted() {
		this.getPortalIndustryCourseList()
	},
	methods: {
		openWindow() {
			window.open('https://www.liupaoinc.com/wuzhou-mall-pc-web/#/')
		},
		//打开产品详情
		openGoods(data) {
			this.goodsInfo = data
			this.imgLoading = true
      if(data.mallThirdId && data.mallPushFlag == 1){
        this.getqrCodeApi({
          "page": "pages/goodsDetail/goodsDetail",
          "scene": '' + data.mallThirdId,
        }).then(res => {
          this.goodsInfo.qrCode = `data:image/png;base64,${res}`
          this.imgLoading = false
        })
      }
			this.showGoods = true
		},

		async getPortalIndustryCourseList() {
			//获取发展历程
			let { rows } = await this.getPortalIndustryCourseListApi()
			this.courseList = rows
			this.courseList.forEach((item) => {
				item.nodeTime = this.formatDate(item.nodeTime, 'YYYY.MM.DD')
			})
			this.pollingEvent()
			//获取热门商品推荐
			let data = await this.getWuZhouMallGoodsListApi({limit: 9})
			this.goodsList = data.rows
			this.setGoodsInfo()
			this.$emit('escLoading')
		},
		setGoodsInfo() {
			this.goodsList.forEach(item => {
				this.getGoodsInfoApi(item.id).then((res) => {
          item.mallThirdId = res.mallThirdId
				})
			})
		},

		leftEvent() {
			if (this.bannerIndex == 0) {
				this.bannerIndex = this.courseList.length - 1
			} else {
				this.bannerIndex = this.bannerIndex - 1
			}
		},
		rightEvent() {
			if (this.bannerIndex == this.courseList.length - 1) {
				this.bannerIndex = 0
			} else {
				this.bannerIndex = this.bannerIndex + 1
			}
		},
		//产业发展自动轮询
		pollingEvent() {
			let that = this
			this.rightEvent()
			setTimeout(function () {
				that.pollingEvent()
			}, 2000)
		}
	},
}
</script>
<style lang="less" scoped>
.index {
	width: 100%;
	background-color: #f3f3f3;
	background-image: url('../../assets/images/z-bg.png');
	background-size: 5.4rem 5.4rem;

	.banner-div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 14.4rem;
		margin: 0 auto;
		padding: 1.5rem 0 1.1rem 0;

		.left-div {
			cursor: pointer;
			position: relative;
			width: 0.56rem;
		}

		.left-div:hover {
			animation: leftAnima 1s infinite;
		}

		@keyframes rightAnima {
			from {
				left: 0rem;
			}

			50% {
				left: 0.05rem;
			}

			to {
				left: 0rem;
			}
		}

		.info-div {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 0 0.6rem;

			.name-div {
				font-size: 0.4rem;
				font-family: SCBold;
				font-weight: bold;
				color: #484848;
				margin-top: 1.5rem;
			}

			.test-div {
				font-size: 0.14rem;
				font-family: SCBold;
				font-weight: bold;
				color: #484848;
				margin-top: 0.1rem;
			}

			.info-info {
				font-size: 0.14rem;
				font-family: SCBold;
				font-weight: bold;
				color: #484848;
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 8.8rem;

				.info-img {
					max-width: 10rem;
					height: 6rem;
					margin: 0.4rem 0;
				}

				.info-test-div {
					width: 10rem;
				}

				.h-div {
					display: flex;
					flex-direction: column;
					align-items: center;
					font-size: 0.4rem;
					font-family: SCBold;
					font-weight: bold;
					color: #484848;
					letter-spacing: 0.04rem;
				}

				.testear-div {
					display: flex;
					flex-direction: column;
					align-items: center;
					font-size: 0.14rem;
					font-family: SCBold;
					font-weight: bold;
					color: #484848;
					letter-spacing: 0.01rem;
				}
			}

			.t-div {
				display: flex;
				margin-top: 0.83rem;

				.for-div {
					display: flex;
					align-items: center;
				}

				.q-div {
					position: relative;
					cursor: pointer;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 0.16rem;
					height: 0.16rem;
					background: #f3f3f3;
					border: 0.02rem solid #484848;
					border-radius: 0.16rem;

					.nq-div {
						width: 0.1rem;
						height: 0.1rem;
						background: #484848;
						border-radius: 0.1rem;
					}
				}

				:after {
					content: attr(data-star);
					position: absolute;
					font-size: 0.12rem;
					color: #484848;
					top: 0.2rem;
				}

				.t-h-div {
					width: 2.31rem;
					height: 0.02rem;
					background-color: #d6d6d6;
				}
			}
		}

		.right-div {
			cursor: pointer;
			position: relative;
			width: 0.56rem;
		}

		.right-div:hover {
			animation: rightAnima 1s infinite;
		}

		@keyframes leftAnima {
			from {
				right: 0rem;
			}

			50% {
				right: 0.05rem;
			}

			to {
				right: 0rem;
			}
		}
	}

	.show-div {
		width: 100%;
		height: 10.8rem;
		background: #eaeaea;
		display: flex;
		flex-direction: column;
		align-items: center;

		.name-div {
			font-size: 0.4rem;
			font-family: SCBold;
			font-weight: bold;
			color: #484848;
			margin-top: 1.5rem;
		}

		.test-div {
			font-size: 0.14rem;
			font-family: SCBold;
			font-weight: bold;
			color: #484848;
			margin-top: 0.1rem;
		}

		.info-div {
			width: 8.9rem;
			height: 6.12rem;
			display: grid;
			grid-template-columns: 2.24rem 2.18rem 2.24rem 2.24rem;
			grid-template-rows: 1.76rem 0.84rem 0.92rem 0.84rem 1.76rem;
			margin-top: 0.87rem;

			.info-name-div {
				font-size: 0.3rem;
				font-family: SCBold;
				font-weight: bold;
				color: #484848;
				margin: 0.3rem 0 0 0.3rem;
			}

			.info-test-div {
				font-size: 0.16rem;
				font-family: SCBold;
				font-weight: bold;
				color: #484848;
				margin: 0.14rem 0 0 0.3rem;
			}

			div:nth-child(1) {
				grid-column: 1/3;
				grid-row: 1/1;

				img {
					width: 100%;
				}
			}

			div:nth-child(2) {
				grid-column: 3/4;
				grid-row: 1/1;
				background-color: #fff;
			}

			div:nth-child(3) {
				grid-column: 4/5;
				grid-row: 1/3;

				img {
					width: 100%;
				}
			}

			div:nth-child(4) {
				grid-column: 1/1;
				grid-row: 2/4;
				background-color: #fff;
			}

			div:nth-child(5) {
				grid-column: 2/4;
				grid-row: 2/5;

				img {
					width: 100%;
				}
			}

			div:nth-child(6) {
				grid-column: 4/5;
				grid-row: 3/5;
				background-color: #fff;
			}

			div:nth-child(7) {
				grid-column: 1/1;
				grid-row: 4/6;

				img {
					width: 100%;
				}
			}

			div:nth-child(8) {
				grid-column: 2/3;
				grid-row: 5/6;
				background-color: #fff;
			}

			div:nth-child(9) {
				grid-column: 3/5;
				grid-row: 5/6;

				img {
					width: 100%;
				}
			}
		}
	}

	.goods-div {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 14.4rem;
		min-height: 10.8rem;
		margin: 0 auto;

		.name-div {
			font-size: 0.4rem;
			font-family: SCBold;
			font-weight: bold;
			color: #484848;
			margin-top: 1.5rem;
		}

		.test-div {
			font-size: 0.14rem;
			font-family: SCBold;
			font-weight: bold;
			color: #484848;
			margin-top: 0.1rem;
		}

		.info-div {
			width: 100%;
			display: flex;
			justify-content: space-between;
			margin-top: 0.69rem;
      flex-wrap: wrap;

			.info-block1 {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 4rem;
        margin-bottom: 0.5rem;
      overflow: hidden;

				.info-img1 {
					width: 100%;
					position: relative;

					.img-block1 {
						width: 100%;
					}

					.img-block-goods {
						cursor: pointer;
						position: absolute;
						width: 3.24rem;
						left: 0.38rem;
						bottom: 0.7rem;
						transition: all 0.5s;
						border-radius: 0.04rem;
					}

					.img-block-goods:hover {
						width: 3.6rem;
						left: 0.2rem;
					}
				}

				.info-name1 {
					text-align: center;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					width: 100%;
					font-size: 0.24rem;
					font-family: SCBold;
					font-weight: bold;
					color: #484848;
					margin-top: 0.1rem;
				}

				.info-h {
					width: 0.3rem;
					height: 0.02rem;
					background-color: #484848;
					margin-top: 0.2rem;
				}
			}
		}

		.router-div {
			cursor: pointer;
			display: flex;
			align-items: center;
			font-size: 0.16rem;
			font-family: SCBold;
			font-weight: bold;
			color: #484848;
			margin-top: 0.7rem;

			img {
				width: 0.32rem;
				margin-left: 0.1rem;
			}
		}
	}

	.goods-info-body {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		align-items: center;

		.goods-info {
			width: 15rem;
			height: 7.6rem;
			background-color: #fff;
			display: flex;
			border-radius: 0.1rem 0.1rem 0.1rem 0.1rem;

			.goods-img {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 7rem;
				height: 100%;
				background-image: url('../../assets/images/goods-info-bg.png');
				background-size: 100% 100%;

				img {
					width: 3.3rem;
					border-radius: 0.04rem;
				}
			}

			.goods-test {
				position: relative;
				width: 6.2rem;
				height: 5.2rem;
				padding: 1.2rem 0.9rem;

				.info-title {
					font-size: 0.14rem;
					font-family: SCBold;
					font-weight: bold;
					color: rgba(72, 72, 72, 0.6);
					line-height: 0.14rem;
				}

				.info-title1 {
					width: 4rem;
					font-size: 0.36rem;
					font-family: SCBold;
					font-weight: bold;
					color: #484848;
					line-height: 0.36rem;
					letter-spacing: 0.03rem;
					margin: 0.2rem 0 0.4rem 0;
				}

				.info-title2 {
					width: 4rem;
					font-size: 0.2rem;
					font-family: SCBold;
					font-weight: bold;
					color: #484848;
					line-height: 0.2rem;
					margin: 0.2rem 0 0.4rem 0;
				}

				.info-title3 {
					width: 4rem;
					font-size: 0.56rem;
					font-family: SCBold;
					font-weight: bold;
					color: #484848;
					line-height: 0.56rem;
					margin: 0.2rem 0 0.4rem 0;
				}

				.esc-img {
					cursor: pointer;
					position: absolute;
					right: 0.4rem;
					top: 0.4rem;
					width: 0.24rem;
					height: 0.24rem;
				}

				.qr-code-img {
					position: absolute;
					width: 2.4rem;
					height: 2.4rem;
					right: 0.4rem;
					bottom: 1.2rem;
					img{
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
}
</style>
