<template>
	<div class="feedback_box">
		<div class="contact_open_btn" @click="showFeedBack = true" v-show="!showFeedBack">
      <i class="el-icon-edit"></i>
    </div>
		<div class="tea-div" v-show="showFeedBack">
      <div class="close_btn" @click="showFeedBack = false;">
        <i class="el-icon-close"></i>
      </div>
			<div class="name-div">联系我们</div>
			<div class="test-div">CONTACT</div>
			<div class="map-info">
				<div class="contact-div">
					<div class="message_box">
            <div class="message_title">留言:</div>
            <div class="message_input">
              <el-input v-model="msgText" type="textarea" :maxlength="100" :autosize="{minRows:4,maxRows:4}" :show-word-limit="true" placeholder="请输入留言" resize="none"></el-input>
            </div>
          </div>
          <div class="info_box">
            <div class="company_box">
              <div class="message_title">姓名:</div>
              <div class="message_input">
                <el-input v-model="userName" :maxlength="10" :show-word-limit="true" placeholder="请输入姓名" resize="none"></el-input>
              </div>
            </div>
            <div class="company_box">
              <div class="message_title" >联系方式:</div>
              <div class="message_input" >
                <el-input v-model="userMobile" :maxlength="11" :show-word-limit="true" placeholder="请输入联系方式" resize="none"></el-input>
              </div>
            </div>
          </div>
          <div class="btn_box" style="">
            <el-button @click="submitInfo" type="primary">提交</el-button>
          </div>
          
				</div>
				<div class="map-div">
        </div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'feedback',
	components: {},
	data() {
		return {
      msgText: '',
      userName: '',
      userMobile: '',
      showFeedBack: false,
		}
	},
	watch: {
	},
	methods: {
		async submitInfo(){
      
      if(this.msgText && this.msgText.length > 0 && this.userName && this.userName.length > 0 && this.userMobile && this.userMobile.length > 0 ){
        let data = {
          title: '申请成为会员',
          content:this.msgText,
          contactName:this.userName,
          contactMobile:this.userMobile, 
        }
        let res = await this.postPortalFeedbackInfoApi(data)
        if(res){
          this.$message({
            type: 'success',
            message: `提交信息成功`
          });
          this.msgText = ''
          this.userName = ''
          this.userMobile = ''
        }
      }else{
        this.$alert('请填写相关信息后再提交', '提示', {
          confirmButtonText: '确定',
          callback: action => {
          }
        });
      }
      
    },
	},
}
</script>
<style lang="less" scoped>
.tea-div {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0.2rem;

  .introduce-test-info {
    width: 14.4rem;
    font-size: 0.14rem;
    margin-top: 0.2rem;
    font-family: SCBold;
    font-weight: bold;
    color: #484848;
    line-height: 0.3rem;
    letter-spacing: 1px;
  }

  .info-div {
    display: flex;
    justify-content: space-between;
    width: 14.4rem;
    margin: 0.49rem auto 0 auto;

    .info-block {
      position: relative;
      width: 4.4rem;
      height: 6.8rem;
      border-radius: 0.06rem;
      overflow: hidden;

      .info-block-img {
        width: 100%;
        height: 2.58rem;
        border-radius: 0.06rem 0.06rem 0 0;
      }

      .info-block-name {
        margin: 0.4rem 0.3rem 0 0.3rem;
        font-size: 0.2rem;
        font-family: SCBold;
        font-weight: bold;
        color: #484848;
      }

      .info-block-test {
        position: absolute;
        z-index: 2;
        margin: 0.24rem 0.3rem 0.8rem 0.3rem;
        font-size: 0.14rem;
        font-family: SCBold;
        font-weight: bold;
        color: #484848;
      }

      .info-block-icon {
        width: 3.5rem;
        height: 1.6rem;
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 0 0 0.06rem 0;
      }
    }
  }

  .map-info {
    width: 14.4rem;
    margin: 0 auto;
    display: flex;

    .contact-div {
      font-size: 0.2rem;
      font-family: SCBold;
      font-weight: bold;
      color: #484848;
      line-height: 0.3rem;
      letter-spacing: 0.02rem;
    }
  }

  .router-div {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 0.16rem;
    font-family: SCBold;
    font-weight: bold;
    color: #484848;
    margin-top: 0.7rem;

    img {
      width: 0.32rem;
      margin-left: 0.1rem;
    }
  }
}
.name-div {
  font-size: 0.4rem;
  font-family: SCBold;
  font-weight: bold;
  color: #484848;
  margin-top: 1.5rem;
}

.test-div {
  font-size: 0.14rem;
  font-family: SCBold;
  font-weight: bold;
  color: #484848;
  margin-top: 0.1rem;
}
.feedback_box {
  z-index: 999;
	.contact_open_btn {
    background: #409EFF;color:#FFFFFF;padding: 10px 15px;font-size: 26px;position: fixed;    bottom: 20px;    right: 0;    z-index: 999;
  }
  .tea-div {
    position: fixed;    bottom: 20px;    right: 0;    width: 100%; z-index: 999;
    .close_btn {
      border-radius:20px;width:20px;height:20px;overflow: hidden;position: absolute;
      top: 20px;right: 20px;border: 1px solid #333;color:#333;font-size: 30px;display: flex;align-items: center;justify-content: center;padding: 0;cursor: pointer;
    }
    .name-div {
      margin-top: 0.5rem;
    }
    .map-info {
      width: 80%;    margin: auto;margin-top:20px;
      .contact-div {
        width: 100%;
        .message_box {
          display: flex;
          .message_title {
            width: 100px; 

          }
          .message_input {
            flex:1;margin-left:20px;
          }
        }
        .info_box {
          .company_box {
            flex:1;display:flex;
          margin-top:20px;
            .message_title {
              width:100px;
            }
            .message_input {
              flex:1;margin-left:20px;
            }
          }
        }
        .btn_box {
          display:flex; justify-content: flex-end;margin-top:20px;
        }
      }
    }
  }
}
</style>
