<template>
  <div class="authention">
    <div class="first-screen">
      <div class="header-div">
        <div class="header-left"><img src="../../assets/images/LOGO.png" class="header-logo" @click="returnHome" />
          <div class="header-text" @click="returnHome">茶船古道企业查询中心</div>
          <div class="return-btn" @click="returnHome">回到官网</div>
        </div>
        <div class="search-box-2" v-if="showScreen == 2">
          <input class="search-input-2" v-model="pageData.nameOrCode" placeholder="请输入信用代码或企业名称" />
          <div class="search-btn-2" @click="searchEvent">查询</div>
        </div>
      </div>
      <div class="screen-01" v-if="showScreen == 1">
        <div class="title-text">企业查询</div>
        <div class="search-box">
          <input class="search-input" v-model="pageData.nameOrCode" placeholder="请输入信用代码或企业名称" />
          <div class="search-btn" @click="searchEvent">查询</div>
        </div>
      </div>
      <authentionList :total="total" :companyList="companyList" @openInfo="openInfo"
        @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" v-show="showScreen == 2">
      </authentionList>
      <authentionInfo ref="authentionInfo" @eventReturn="eventReturn" v-show="showScreen == 3"></authentionInfo>
    </div>
  </div>
</template>

<script>
import authentionList from './authentionList'
import authentionInfo from './authentionInfo'
import { mapGetters } from 'vuex'
export default {
  name: 'authention',
  data() {
    return {
      showScreen: 1,
      companyList: [],
      total: 0,
      pageData: {
        page: 1,
        limit: 10,
        nameOrCode: ''
      },
    }
  },
  components: {
    authentionList,
    authentionInfo
  },
  computed: {
    ...mapGetters(['ossDomain']),
  },
  methods: {
    eventReturn() {
      this.showScreen = 2
    },
    openInfo(val) {
      this.showScreen = 3
      this.$refs.authentionInfo.getInfo(val)
    },
    handleSizeChange(val) {
      this.pageData.limit = val.limit
      this.searchEvent()
    },
    handleCurrentChange(val) {
      this.pageData.page = val.page
      this.searchEvent()
    },
    returnHome() {
      this.$router.push('/')
    },
    searchEvent() {
      this.getOrganizeListApi(this.pageData).then(res => {
        this.companyList = res.rows
        this.total = res.total

        this.showScreen = 2
      }).catch(err => {
        return false
      })
    },
  },
  mounted() {
    this.$emit("hideHeaderEvent", true);
    this.$emit("hideFootEvent", true);
    this.showScreen = 1
  },
  beforeDestroy() {
    this.$emit("hideHeaderEvent", false);
    this.$emit("hideFootEvent", false);
  },
}
</script>
<style lang="less" scoped>
.authention {
  width: 100%;
  min-width: 1200px;
  background-image: url('../../assets/images/authen_bg.jpg');
  background-size: 100% 100%;
  height: 100vh;
  overflow: hidden;

  .first-screen {
    width: 1100px;
    padding: 0 50px;
    margin: auto;

    .header-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 90px;

      .header-left {
        display: flex;
        align-items: center;

        .header-logo {
          width: 42px;
          height: 42px;
        }

        .header-text {
          font-weight: bold;
          font-size: 24px;
          color: #000000;
          margin-left: 10px;
        }

        .return-btn {
          font-weight: 400;
          font-size: 12px;
          color: #000000;
          margin-left: 60px;
          cursor: pointer;
        }
      }

      .search-box-2 {
        width: 460px;
        border-radius: 44px;
        display: flex;
        border: 2px solid #BE1D20;
        align-items: center;
        background: #FFFFFF;

        .search-input-2 {
          background: none;
          padding: 0;
          margin: 0;
          padding-left: 20px;
          font-size: 14px;
          border: none;
          outline: none;
          flex: 1;
          height: 48px;
        }

        .search-btn-2 {
          width: 80px;
          height: 36px;
          font-size: 14px;
          color: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #BE1D20;
          border-radius: 36px;
          margin-right: 4px;
          cursor: pointer;
        }
      }
    }

    .screen-01 {
      margin-top: 180px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .title-text {
      font-size: 54px;
    }

    .search-box {
      width: 800px;
      margin-top: 36px;
      border-radius: 64px;
      display: flex;
      border: 2px solid #BE1D20;
      align-items: center;
      background: #FFFFFF;

      .search-input {
        background: none;
        padding: 0;
        margin: 0;
        padding-left: 24px;
        font-size: 20px;
        border: none;
        outline: none;
        flex: 1;
        height: 64px;
      }

      .search-btn {
        width: 100px;
        height: 56px;
        font-size: 20px;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #BE1D20;
        border-radius: 56px;
        margin-right: 4px;
        cursor: pointer;
      }
    }

  }
}
</style>
