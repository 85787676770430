const utils = {
	install(Vue) {
		Vue.prototype.openRouter = function (link) {
			if (link.indexOf('http') === 0) {
				window.open(link)
			} else if (link === '#') {
				alert('页面开发中')
			} else {
				if (this.$route.path != link) this.$router.push(link)
			}
		}
		Vue.prototype.formatDate = function (data, format = 'YYYY-MM-DD HH:mm:ss') {
			let date = new Date(data)
			let time = {
				'M+': date.getMonth() + 1,
				'D+': date.getDate(),
				'H+': date.getHours(),
				'm+': date.getMinutes(),
				's+': date.getSeconds(),
			}

			if (/(y+)/i.test(format)) {
				format = format.replace(
					RegExp.$1,
					(date.getFullYear() + '').substr(4 - RegExp.$1.length)
				)
			}

			for (let k in time) {
				if (new RegExp('(' + k + ')').test(format)) {
					format = format.replace(
						RegExp.$1,
						RegExp.$1.length === 1
							? time[k]
							: ('00' + time[k]).substr(('' + time[k]).length)
					)
				}
			}
			return format
		}
		Vue.prototype.deepCopy = function (data) {
			//string,number,bool,null,undefined,symbol
			//object,array,date
			if (data && typeof data === 'object') {
				//针对函数的拷贝
				if (typeof data === 'function') {
					let tempFunc = data.bind(null)
					tempFunc.prototype = Vue.prototype.deepCopy(data.prototype)
					return tempFunc
				}

				switch (Object.prototype.toString.call(data)) {
					case '[object String]':
						return data.toString()
					case '[object Number]':
						return Number(data.toString())
					case '[object Boolean]':
						return new Boolean(data.toString())
					case '[object Date]':
						return new Date(data.getTime())
					case '[object Array]':
						var arr = []
						for (let i = 0; i < data.length; i++) {
							arr[i] = Vue.prototype.deepCopy(data[i])
						}
						return arr

					//js自带对象或用户自定义类实例
					case '[object Object]':
						var obj = {}
						for (let key in data) {
							//会遍历原型链上的属性方法，可以用hasOwnProperty来控制 （obj.hasOwnProperty(prop)
							obj[key] = Vue.prototype.deepCopy(data[key])
						}
						return obj
				}
			} else {
				//string,number,bool,null,undefined,symbol
				return data
			}
		}
	},
}
export default utils
