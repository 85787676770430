<template>
	<div class="intangibleCultural">
		<div class="bg-div">
			<div class="bg-name">千百年传承的制茶技艺</div>
			<div class="bg-test">TEA MAKING TECHNIQUES</div>
			<div class="bg-info">
				“中国传统制茶技艺及其相关习俗”相关44个项目已先后列入国务院批准公布的国家级非物质文化遗产代表性项目名录。为确保该遗产项目的存续力，增强传承活力，相关社区、群体和个人于2020年12月成立保护工作组，联合制定了《中国传统制茶技艺及其相关习俗五年保护计划（2021—2025）》，将鼓励传承人按照传统方式授徒传艺，依托中职院校和高等院校培养专门人才，巩固代际传承；举办保护传承培训班，加强能力建设；建立研学基地，编写普及读本，开展相关巡展活动，提高青少年的保护意识。同时，通过加强确认和管理、提升建档水平、开展学术研究、完善保护协作机制、维护实践场所、组织多种形式的宣传活动等措施，实施协同保护行动。文化和旅游部和相关地方政府将积极支持相关社区、群体和个人组织实施系列保护措施，做好该遗产项目的传承与实践。
			</div>
		</div>
		<div class="user-div">
			<div class="info-div">
				<div class="info-block1" v-for="item in heritageList" :key="item.id">
					<div class="info-img1">
						<img class="img-block1" src="../../assets/images/goods-bg.png" alt="" />
						<div class="img-fy">
							<div class="img-fy-div" @click="openGoods(item)">
								<img :src="ossDomain + item.img" alt="" />
							</div>
						</div>
					</div>
					<div class="info-name1">{{ item.name }}</div>
					<div class="info-h"></div>
				</div>
			</div>
		</div>
		<div class="goods-info-body" v-if="showGoods" @click="showGoods = false">
			<div class="goods-info" @click.stop>
				<img class="esc-img" @click="showGoods = false" src="../../assets/images/esc-icon.png" alt="" />
				<div class="goods-img">
					<img :src="
						(goodsOrCharacter == 2 ? (mallOssDomain + goodsInfo.img) : (ossDomain + characterInfo.img))
						" alt="" />
					<div class="goods-name-select">
						<div @click="goodsOrCharacter = 1">人物简介</div>
						<div @click="goodsOrCharacter = 2">非遗作品</div>
					</div>
					<div class="select-div" :style="{ left: (goodsOrCharacter == 1 ? '0.72' : '1.76') + 'rem' }"></div>
				</div>
				<div class="goods-test">
					<div class="info-title" v-if="goodsOrCharacter == 2">商品名</div>
					<div class="info-title1">
						{{ goodsOrCharacter == 2 ? goodsInfo.name : characterInfo.name }}
					</div>
					<div class="info-title" v-if="goodsOrCharacter == 2">店铺</div>
					<div class="info-title2" v-if="goodsOrCharacter == 2">
						{{ goodsInfo.organizeName }}
					</div>
					<div class="info-title" v-if="(goodsInfo.typeParentName || goodsInfo.typeName) && goodsOrCharacter == 2">商品类目</div>
					<div class="info-title2" v-if="goodsOrCharacter == 2">
						{{ goodsInfo.typeParentName ? goodsInfo.typeParentName : '' }}{{ goodsInfo.typeName  ? ' - ' + goodsInfo.typeName : '' }}
					</div>
					<div class="info-title" v-if="goodsInfo.synopsis && goodsOrCharacter == 2">
						商品描述
					</div>
					<div v-if="goodsOrCharacter == 2" class="info-title2">
						{{ goodsInfo.synopsis }}
					</div>
					<div v-if="goodsOrCharacter == 1" class="info-title2_2" v-html="characterInfo.details"></div>
					<div class="info-title" v-if="goodsOrCharacter == 2">商品价格</div>
					<div class="info-title3" v-if="goodsOrCharacter == 2">
						¥{{ goodsInfo.price }}
					</div>
					<div class="button-div" v-if="goodsOrCharacter == 2 && goodsInfo.mallPushFlag == 1 && goodsInfo.mallThirdId" @click="openWindow(goodsInfo)">
						点击购买
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'intangibleCultural',
	components: {},
	data() {
		return {
			imgLoading: false,
			heritageList: [],
			goodsInfo: {},
			characterInfo: {},
			goodsOrCharacter: 1,
			showGoods: false,
      mallOssDomain: '',
		}
	},
	computed: {
		...mapGetters(['ossDomain']),
	},
	created() {
		this.$emit('openLoading')
		this.getMallOssDomain()
		this.getPortalIntangibleCulturalHeritageList()
	},
	methods: {
    getMallOssDomain(){
      this.getMallOssDomainApi().then(res => {
        this.mallOssDomain = res.ossDomain
      }).catch(err => {

      })
    },
		openGoods(data) {
			this.goodsInfo = data.goodsInfo
			this.characterInfo = data
			this.showGoods = true
		},
		openWindow(data) {
      if(data.mallThirdId && data.mallPushFlag == 1){
        this.getWxAppApi({
          "page": "pages/goodsDetail/goodsDetail",
          "query": 'id=' + data.mallThirdId,
        }).then(res => {
          location.href = res
        })
      }
		},
		getPortalIntangibleCulturalHeritageList() {
			this.getPortalIntangibleCulturalHeritageListApi().then((res) => {
				this.heritageList = res.rows
				this.setGoodsInfo()
				this.$emit('escLoading')
			})
		},
		setGoodsInfo() {
			this.heritageList.forEach(item => {
				this.getPortalIntangibleCulturalHeritageDetailApi(item.id).then((res) => {
					this.$set(item,'goodsInfo',res.goodsVo)
					item.representImg = res.img
				})
			})
		},
	},
}
</script>
<style lang="less" scoped>
.intangibleCultural {
	width: 100%;
	background-color: #f3f3f3;
	background-image: url('../../assets/images/z-bg.png');
	background-size: 5.4rem 5.4rem;

	.bg-div {
		position: relative;
		width: 100%;
		background-image: url('../../assets/images/intangibleCultural-bg-web.png');
		background-size: 100% 100%;

		.bg-name {
			display: flex;
			justify-content: center;
			font-size: 0.2rem;
			font-family: SCBold;
			font-weight: bold;
			color: #ffffff;
			padding-top: 0.4rem;
		}

		.bg-test {
			display: flex;
			justify-content: center;
			font-size: 0.12rem;
			font-family: SCBold;
			font-weight: bold;
			color: #ffffff;
			margin-top: 0.1rem;
		}

		.bg-info {
			padding: .1rem .3rem;
			font-size: 0.12rem;
			font-family: SCBold;
			color: #ffffff;
			line-height: .16rem;
		}
	}

	.user-div {
		width: 100%;
		background: #ffffff;

		.info-div {
			padding: .6rem .4rem;
			display: flex;
			justify-content: space-between;
			flex-flow: row wrap;
			align-content: flex-start;

			.info-block1 {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 4rem;
				margin: 0.5rem 0;
      overflow: hidden;

				.info-img1 {
					position: relative;
					width: 100%;

					.img-block1 {
						width: 100%;
					}

					.img-fy {
						left: 0.3rem;
						top: -0.3rem;
						position: absolute;
						width: 2.5rem;
						height: 2.54rem;

						.img-fy-div {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							/*过渡效果*/
							transition: all 1s;
							backface-visibility: hidden;

							img {
								width: 100%;
								max-height: 100%;
							}
						}


					}

				}

				.info-name1 {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					font-size: 0.16rem;
					font-family: SCBold;
					font-weight: bold;
					color: #484848;
					margin-top: 0.3rem;
				}

				.info-h {
					width: 0.3rem;
					height: 0.02rem;
					background-color: #484848;
					margin-top: 0.2rem;
				}
			}
		}
	}

	.goods-info-body {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		align-items: center;

		.goods-info {
			position: relative;
			width: 3.5rem;
			height: 5.8rem;
			background-color: #fff;
			border-radius: 0.1rem 0.1rem 0.1rem 0.1rem;

			.esc-img {
				cursor: pointer;
				position: absolute;
				right: 0;
				top: -0.2rem;
				width: 0.16rem;
				height: 0.16rem;
			}

			.goods-img {
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				width: 3.3rem;
				height: 2.88rem;
				margin: .1rem;
				background-image: url('../../assets/images/goods-info-bg.png');
				background-size: 100% 100%;

				img {
					border-radius: 0.04rem;
					margin-top: .3rem;
					width: 1.6rem;
				}

				.goods-name-select {
					position: absolute;
					bottom: 0.4rem;
					display: flex;

					div {
						cursor: pointer;
						font-size: 0.2rem;
						font-family: SCBold;
						font-weight: bold;
						color: #484848;
						line-height: 0.2rem;
						letter-spacing: 0.02rem;
						margin-right: 0.2rem;
					}
				}

				.select-div {
					position: absolute;
					width: 0.6rem;
					height: 0.04rem;
					background-color: #be1d20;
					bottom: 0.2rem;
					border-radius: 0.04rem;
					transition: all 0.5s;
				}
			}

			.goods-test {
				position: relative;
				width: 3.1rem;
				height: 2.5rem;
				padding: 0 .2rem .2rem .2rem;

				.button-div {
					display: flex;
					justify-content: center;
					align-items: center;
					position: absolute;
					bottom: 0.2rem;
					right: 0.2rem;
					width: 1.40rem;
					height: .60rem;
					border: .01rem solid #000000;
					font-size: .2rem;
					font-family: SCBold;
					font-weight: bold;
					color: #000000;
				}

				.info-title {
					font-size: 0.14rem;
					font-family: SCBold;
					font-weight: bold;
					color: rgba(72, 72, 72, 0.6);
					line-height: 0.14rem;
				}

				.info-title1 {
					width: 3rem;
					font-size: 0.16rem;
					font-family: SCBold;
					font-weight: bold;
					color: #484848;
					letter-spacing: 0.03rem;
					margin: 0.05rem 0 0.1rem 0;
				}

				.info-title2 {
					width: 3rem;
					font-size: 0.16rem;
					font-family: SCBold;
					font-weight: bold;
					color: #484848;
					line-height: 0.2rem;
					margin: 0.05rem 0 0.1rem 0;
				}

				.info-title2_2 {
					height: 2rem;
					font-size: 0.2rem;
					font-family: SCBold;
					font-weight: bold;
					color: #484848;
					line-height: 0.2rem;
					margin: 0.05rem 0 0.1rem 0;
					overflow-y: scroll;
				}

				.info-title3 {
					width: 3rem;
					font-size: 0.2rem;
					font-family: SCBold;
					font-weight: bold;
					color: #484848;
					margin: 0.05rem 0 0.1rem 0;
				}


			}
		}
	}
}
</style>
