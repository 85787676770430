<template>
	<div class="news-info">
		<div class="body-div">
			<div class="name-div">
				{{ dataInfo.title }}
			</div>
			<div class="time-div">{{ formatDate(dataInfo.releaseTime) }}</div>
			<div class="body-info" v-html="dataInfo.details"></div>
			<div class="body-fj" v-if="dataInfo.annexMap.length != 0">
				<div>附件：</div>
				<div v-for="(item, index) in dataInfo.annexMap" :key="index">
					<div class="body-fj-name" @click="openFj(item)">{{ item.name }}</div>
				</div>
			</div>
			<div class="cz-div">
				<!-- <div class="cz-left">
					<img src="../../assets/images/icon-left.png" alt="" />
					<div>上一篇</div>
				</div> -->
				<div class="cz-cont" @click="$router.push(`/view/admin/news`)">返回栏目</div>
				<!-- <div class="cz-right">
					<div>下一篇</div>
					<img src="../../assets/images/icon-right.png" alt="" />
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'newsInfo',
	components: {},
	data() {
		return {
			dataInfo: {
				releaseTime: null,
				title: null,
				details: null,
				annexMap: []
			},
		}
	},
	computed: {
		...mapGetters(['ossDomain']),
	},
	created() {
		this.getPortalMessageNoticeInfo()
	},
	methods: {
		openFj(data) {
			window.open(this.ossDomain + data.linkUrl)
		},
		getPortalMessageNoticeInfo() {
			this.getPortalMessageNoticeInfoApi(this.$route.query.id).then((res) => {
        res.details = res.details.replace('img','img style="max-width:100%;" ')
        res.details = res.details.replace(/(height=\")\d*\"/g,'')
				this.dataInfo = res
			})
		},
	},
}
</script>
<style lang="less" scoped>
.news-info {
	width: 100%;
	background-color: #f3f3f3;
	background-image: url('../../assets/images/z-bg.png');
	background-size: 5.4rem 5.4rem;

	.body-div {
		width: 14.4rem;
		margin: 0 auto;
		padding: 0.4rem 0 1.2rem 0;

		.name-div {
			font-size: 0.2rem;
			font-family: SCBold;
			font-weight: bold;
			color: #484848;
			letter-spacing: 0.02rem;
			margin-bottom: 0.2rem;
		}

		.time-div {
			font-size: 0.12rem;
			font-family: SCBold;
			font-weight: bold;
			color: #999999;
			letter-spacing: 0.01rem;
			margin-bottom: 0.4rem;
		}

		.body-info {
			width: 100%;
		}

		.body-fj {
			display: flex;
			justify-content: flex-end;
			margin-top: .1rem;
			width: 100%;
			font-size: 0.24rem;
			font-family: SCBold;

			.body-fj-name {
				cursor: pointer;
			}
		}

		.cz-div {

			display: flex;
			justify-content: center;
			align-items: center;
			// justify-content: space-between;
			// align-items: center;
			width: 100%;
			margin-top: 0.9rem;

			img {
				width: 0.56rem;
				height: 0.56rem;
			}

			.cz-left,
			.cz-right,
			.cz-cont {
				cursor: pointer;
				display: flex;
				align-items: center;
				font-size: 0.14rem;
				font-family: SCBold;
				font-weight: bold;
				color: #484848;

				div {
					margin: 0 0.2rem;
				}
			}
		}
	}
}
</style>
