<template>
	<div class="index">
		<indexHeader v-if="!hideHeader"></indexHeader>
		<router-view @openLoading="openLoading" @escLoading="escLoading" @hideHeaderEvent="hideHeaderEvent" @hideFootEvent="hideFootEvent" />
		<indexFoot v-if="!hideFoot"></indexFoot>
    <feedback></feedback>
		<indexLoading v-show="showLoading"></indexLoading>
	</div>
</template>

<script>
import indexHeader from '../../components/header'
import indexFoot from '../../components/foot'
import feedback from '../../components/feedback'
import indexLoading from './loading'
export default {
	name: 'index',
	components: {
		indexHeader,
		indexFoot,
		indexLoading,
    feedback,
	},
	data() {
		return {
			showLoading: false,
      hideHeader: false,
      hideFoot: false,
		}
	},

	methods: {
		openLoading() {
			this.showLoading = true
		},
		escLoading() {
			this.showLoading = false
		},
    hideFootEvent(data) {
      this.hideFoot = data
    },
    hideHeaderEvent(data) {
      this.hideHeader = data
    }
	},
}
</script>
<style lang="less" scoped>
.index{
  width: 100%;
}

::v-deep .el-icon-error {
    font-size: 0.24rem;
}
</style>
