const setting = (Vue, request) => {
	// 发展历程
	Vue.prototype.getPortalIndustryCourseListApi = () => {
		return request({
			url: `/portal/industry/course`,
			method: 'GET',
		})
	}
	//获取图片的前缀
	Vue.prototype.getImagePreApi = () => {
		return request({
			url: `/config/system`,
			method: 'GET',
		})
	}
	//获取小程序码
	Vue.prototype.getqrCodeApi = (data) => {
		return request({
			url: `/support/wechat/applet/url/getUnlimitedQRCode`,
			params: data,
			method: 'GET',
		})
	}
	//获取企业列表
	Vue.prototype.getPortalEnterpriseListApi = (grade) => {
		return request({
			url: `/portal/enterprise?label=${grade}`,
			method: 'GET',
		})
	}
	//获取企业详情
	Vue.prototype.getOrganizeInfoApi = (id) => {
		console.log(id);
		return request({
			url: `/organize/${id}`,
			method: 'GET',
		})
	}
	//企业茶园列表
	Vue.prototype.getPortalEnterpriseGardenListApi = (enterpriseId) => {
		return request({
			url: `/portal/enterprise/garden?enterpriseId=${enterpriseId}`,
			method: 'GET',
		})
	}
	//企业茶仓列表
	Vue.prototype.getPortalEnterpriseStorehouseListApi = (enterpriseId) => {
		return request({
			url: `/portal/enterprise/storehouse?enterpriseId=${enterpriseId}`,
			method: 'GET',
		})
	}
	//非遗系列列表
	Vue.prototype.getPortalIntangibleCulturalHeritageListApi = () => {
		return request({
			url: `/portal/intangible-cultural-heritage`,
			method: 'GET',
		})
	}
  
	//获取非遗系列产品详情
	Vue.prototype.getPortalIntangibleCulturalHeritageDetailApi = (id) => {
		return request({
			// url: `/support/mall/goods/${id}`,
			url: `/portal/intangible-cultural-heritage/${id}`,
			method: 'GET',
		})
	}
	//信息公开列表
	Vue.prototype.getPortalMessageNoticeListApi = (data) => {
		return request({
			url: `/portal/message/notice`,
			params: data,
			method: 'GET',
		})
	}
	//信息公开详情
	Vue.prototype.getPortalMessageNoticeInfoApi = (id) => {
		return request({
			url: `/portal/message/notice/${id}`,
			method: 'GET',
		})
	}
	//获取企业产品推荐列表
	Vue.prototype.getRecommendedProductsByEnterprisesListApi = (data) => {
		return request({
			url: `/goods`,
			method: 'GET',
      params:data,
		})
	}
	//获取企业产品推荐详情
	Vue.prototype.getEnterpriseGoodsInfoApi = (id) => {
		return request({
			url: `/goods/${id}`,
			method: 'GET',
		})
	}
	//获取产品详情
	Vue.prototype.getGoodsInfoApi = (id) => {
		return request({
			// url: `/support/mall/goods/${id}`,
			url: `/goods/${id}`,
			method: 'GET',
		})
	}
	//获取热门商品
	Vue.prototype.getWuZhouMallGoodsListApi = (data) => {
		return request({
			// url: `/support/mall/goods`,
			url: `/goods`,
			params: data,
			method: 'GET',
		})
	}
	//获取商城ossdomain
	Vue.prototype.getMallOssDomainApi = (data) => {
		return request({
			// url: `/support/mall/goods`,
			url: `/support/mall/goods/system`,
			params: data,
			method: 'GET',
		})
	}
	//跳转小程序接口
	Vue.prototype.getWxAppApi = (data) => {
		return request({
			url: `/support/wechat/applet/url/scheme`,
			params: data,
			method: 'GET',
		})
	}
	//查询品牌认证接口
	Vue.prototype.getAuthentionInfoApi = (data) => {
		return request({
			url: `/regionalBrand/auth/organize/infoOnOrganizeNameOrCode`,
			params: data,
			method: 'GET',
		})
	}
	//模糊查询-企业列表
	Vue.prototype.getOrganizeListApi = (data) => {
		return request({
			url: `/organize/list/NameOrCode`,
			params: data,
			method: 'GET',
		})
	}
	//企业列表-详情
	Vue.prototype.getOrganizeAuthInfoApi = (organizeId) => {
		return request({
			url: `/regionalBrand/auth/organize/infoOnOrganizeId/${organizeId}`,
			method: 'GET',
		})
	}
	//企业列表-详情
	Vue.prototype.postPortalFeedbackInfoApi = (data) => {
		return request({
			url: `/portal/feedback/add`,
			method: 'POST',
      data:data
		})
	}
}
export default setting
