<template>
	<div class="teaEnterprises">
		<div class="enterprise-div">
			<div class="select-name-div">
				<div class="name-div" @click="selectGrade(1)">会员企业</div>
				<div class="name-div" @click="selectGrade(2)">银标企业</div>
				<div class="name-div" @click="selectGrade(3)">金标企业</div>
				<div class="select-div" :style="{ left: gradeLeft[grade] + 'rem' }"></div>
			</div>
			<div class="info-div">
				<!-- <img class="icon-div" src="../../assets/images/icon-left.png" alt="" /> -->
				<div class="info-img">
					<div class="info-block" :style="{
					borderBottom: `#be1d20 ${item.id == selectCompanyId ? 0.04 : 0
						}rem solid`,
				}" v-for="(item, index) in enterpriseList" :key="item.id" @click="selectCompany(item), (selectIndex = index)">
						<img :src="ossDomain + item.logo" alt="" />
					</div>
				</div>
				<!-- <img class="icon-div" src="../../assets/images/icon-right.png" alt="" /> -->
			</div>
		</div>
		<div class="tea-div">
			<div class="name-div">{{ organizeInfo.name }}</div>
			<div class="test-div">ENTERPRISES</div>
			<img v-if="organizeInfo && organizeInfo.organizeImageVo" :src="ossDomain + organizeInfo.organizeImageVo.imgs[0]" alt="">
			<div class="introduce-test-info">{{ organizeInfo.organizeImageVo.descritpion }}</div>
			<div class="router-div">
				了解更多
				<img src="../../assets/images/icon-right.png" alt="" />
			</div>
		</div>
		<div class="tea-div" v-if="gardenList.length != 0">
			<div class="name-div">茶园展示</div>
			<div class="test-div">TEA FIELDS</div>
			<div class="info-div">
				<div class="info-block" v-for="item in gardenList" :key="item.id">
					<img class="info-block-img" :src="ossDomain + item.img.split(',')[0]" alt="" />
					<div class="info-block-name">[ {{ item.name }} ]</div>
					<div class="info-block-test">
						{{ item.blurb }}
					</div>
					<img class="info-block-icon" src="../../assets/images/tea-icon.png" alt="" />
				</div>
			</div>
		</div>
		<div class="tea-div" v-if="storehouseList.length != 0">
			<div class="name-div">茶仓展示</div>
			<div class="test-div">TEA STOREHOUSE</div>
			<div class="info-div">
				<div class="info-block" v-for="item in storehouseList" :key="item.id">
					<img class="info-block-img" :src="ossDomain + item.img.split(',')[0]" alt="" />
					<div class="info-block-name">[ {{ item.name }} ]</div>
					<div class="info-block-test">
						{{ item.blurb }}
					</div>
					<img class="info-block-icon" src="../../assets/images/tea-icon2.png" alt="" />
				</div>
			</div>
		</div>
    
		<div class="goods-div" v-if="goodsList.length != 0">
			<div class="name-div">热门产品</div>
			<div class="test-div">characteristic</div>
			<div class="info-div">
				<div class="info-block1" v-for="item in goodsList" :key="item.id">
					<div class="info-img1">
						<img class="img-block1" src="../../assets/images/goods-bg.png" alt="" />
						<img class="img-block-goods" @click="openGoods(item)" :src="ossDomain + item.img.split(',')[0]" alt="" />
					</div>
					<div class="info-name1">{{ item.name }}</div>
					<div class="info-h"></div>
				</div>
        <div class="info-block1" v-if="goodsList.length % 3 == 2">
					<div class="info-img1">
						<img class="img-block1" />
						<img class="img-block-goods" />
					</div>
					<div class="info-name1"></div>
					<!-- <div class="info-h"></div> -->
				</div>
			</div>

		</div>
		<div class="goods-info-body" v-if="showGoods" @click="showGoods = false">
			<div class="goods-info" @click.stop>
				<div class="goods-img">
					<img :src="ossDomain + goodsInfo.img.split(',')[0]" alt="" />
				</div>
				<div class="goods-test">
					<div class="info-title">商品名</div>
					<div class="info-title1">{{ goodsInfo.name }}</div>
					<!-- <div class="info-title">店铺</div>
					<div class="info-title2">{{ goodsInfo.organizeName }}</div> -->
					<div class="info-title">商品类目</div>
					<div class="info-title2">{{ goodsInfo.typeParentName || '' }} - {{ goodsInfo.typeName || '' }}</div>
					<div class="info-title" v-if="goodsInfo.trait">商品描述</div>
					<div class="info-title2">
						{{ goodsInfo.trait }}
					</div>
					<div class="info-title">商品价格</div>
					<div class="info-title3">¥{{ goodsInfo.price }}</div>
					<img class="esc-img" @click="showGoods = false" src="../../assets/images/esc-icon.png" alt="" />
					<div v-if="goodsInfo.qrCode"  class="qr-code-img">
						<img :src="goodsInfo.qrCode" alt="" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'teaEnterprises',
	components: {},
	data() {
		return {
			imgLoading: false,
			enterpriseList: [],
			gardenList: [],
			storehouseList: [],
			goodsList: [],
			organizeInfo: {},
			selectIndex: 0,
			selectCompanyId: 0,
			grade: 1,
			gradeLeft: {
				1: 1.1,
				2: 4.55,
				3: 7.94,
			},
			goodsInfo: {},
			showGoods: false,
		}
	},
	computed: {
		...mapGetters(['ossDomain']),
	},
	created() {
		this.$emit('openLoading')
		this.getPortalEnterpriseList()
	},
	methods: {
		selectCompany(data) {
			this.selectCompanyId = data.id
			this.getPortalEnterpriseGardenList(data)
		},
		//打开产品详情
		openGoods(data) {
			this.goodsInfo = data
			this.getEnterpriseGoodsInfoApi(data.id).then(res => {
				console.log(res.mallThirdId);
				if (res.mallThirdId && res.mallPushFlag == 1) {
					this.imgLoading = true
					this.getqrCodeApi({
						"page": "pages/goodsDetail/goodsDetail",
						"scene": '' + data.id,
					}).then(res => {
						this.$set(this.goodsInfo, 'qrCode', `data:image/png;base64,${res}`)
						this.imgLoading = false
					})
				}
				this.showGoods = true
			})

		},
		selectGrade(index) {
			this.selectIndex = 0
			this.grade = index
			this.getPortalEnterpriseList()
		},

		async getPortalEnterpriseList() {
			//获取企业列表
			let { rows } = await this.getPortalEnterpriseListApi(this.grade)
			if (rows.length == 0) {
				this.$emit('escLoading')
				return false
			}
			this.enterpriseList = rows
			this.selectCompanyId = this.enterpriseList[this.selectIndex].id
			if (this.enterpriseList.length == 0) {
				this.$emit('escLoading')
				return false
			}
			this.getPortalEnterpriseGardenList(this.enterpriseList[this.selectIndex])
		},

		async getPortalEnterpriseGardenList(data) {
			//获取企业详情
			let organizeInfo = await this.getOrganizeInfoApi(data.companyId)
      if( organizeInfo && organizeInfo.organizeImageVo){
        organizeInfo.organizeImageVo.imgs = organizeInfo.organizeImageVo.imgs.split(',')
      }
			
			this.organizeInfo = organizeInfo
			//获取茶园列表
			let gardenData = await this.getPortalEnterpriseGardenListApi(data.id)
			this.gardenList = gardenData.rows
			//获取茶仓列表
			let storehouseData = await this.getPortalEnterpriseStorehouseListApi(
				data.id
			)
			this.storehouseList = storehouseData.rows
			//获取推荐产品
			if (data.companyId && data.goodsIds) {
				let reqData = { organizeId: data.companyId, ids: data.goodsIds, limit: 9 }
				let goodsData = await this.getRecommendedProductsByEnterprisesListApi(reqData)
				this.goodsList = goodsData.rows
			  this.setGoodsInfo()
			} else {
				this.goodsList = []
			}
			this.$emit('escLoading')
		},
		setGoodsInfo() {
			this.goodsList.forEach(item => {
				this.getGoodsInfoApi(item.id).then((res) => {
          item.mallThirdId = res.mallThirdId
				})
			})
		},

    
	},
}
</script>
<style lang="less" scoped>
.teaEnterprises {
	background-image: url('../../assets/images/z-bg.png');
	background-size: 5.4rem 5.4rem;
	width: 100%;
	background-color: #f3f3f3;

	.name-div {
		font-size: 0.4rem;
		font-family: SCBold;
		font-weight: bold;
		color: #484848;
		margin-top: 1.5rem;
	}

	.test-div {
		font-size: 0.14rem;
		font-family: SCBold;
		font-weight: bold;
		color: #484848;
		margin-top: 0.1rem;
	}

	.enterprise-div {
		width: 14.4rem;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;

		.select-name-div {
			display: flex;
			position: relative;

			.name-div {
				cursor: pointer;
				font-size: 0.4rem;
				font-family: SCBold;
				font-weight: bold;
				color: #484848;
				margin: 1.5rem 0.9rem 0 0.9rem;
			}

			.select-div {
				position: absolute;
				width: 1.1rem;
				height: 0.04rem;
				background-color: #be1d20;
				border-radius: 0.02rem;
				bottom: -0.24rem;
				transition: all 0.5s;
			}
		}

		.info-div {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 0.49rem;
      flex-wrap: wrap;

			.icon-div {
				width: 0.56rem;
				height: 0.56rem;
			}

			.info-img {
				display: flex;
				flex-flow: row wrap;
				align-content: flex-start;
				width: 12.4rem;
				padding: 0.49rem 0.44rem;

				.info-block {
					box-sizing: border-box;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 2.8rem;
					height: 1.84rem;
					background: #ffffff;
					border-radius: 0.06rem;
					margin: 0.12rem 0.15rem;
					transition: all 0.2s;
      overflow: hidden;

					img {
						height: 1.4rem;
					}
				}
			}
		}
	}

	.tea-div {
		width: 100%;
		background-color: #ffffff;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 0.2rem;

		.introduce-test-info {
			width: 14.4rem;
			font-size: 0.14rem;
			margin-top: 0.2rem;
			font-family: SCBold;
			font-weight: bold;
			color: #484848;
			line-height: 0.3rem;
			letter-spacing: 1px;
		}

		.info-div {
			display: flex;
			justify-content: space-between;
			width: 14.4rem;
			margin: 0.49rem auto 0 auto;

			.info-block {
				position: relative;
				width: 4.4rem;
				height: 6.8rem;
				border-radius: 0.06rem;
      overflow: hidden;

				.info-block-img {
					width: 100%;
					height: 2.58rem;
					border-radius: 0.06rem 0.06rem 0 0;
				}

				.info-block-name {
					margin: 0.4rem 0.3rem 0 0.3rem;
					font-size: 0.2rem;
					font-family: SCBold;
					font-weight: bold;
					color: #484848;
				}

				.info-block-test {
					position: absolute;
					z-index: 2;
					margin: 0.24rem 0.3rem 0.8rem 0.3rem;
					font-size: 0.14rem;
					font-family: SCBold;
					font-weight: bold;
					color: #484848;
				}

				.info-block-icon {
					width: 3.5rem;
					height: 1.6rem;
					position: absolute;
					bottom: 0;
					right: 0;
					border-radius: 0 0 0.06rem 0;
				}
			}
		}

		.map-info {
			width: 14.4rem;
			margin: 0 auto;
			display: flex;

			.contact-div {
				font-size: 0.2rem;
				font-family: SCBold;
				font-weight: bold;
				color: #484848;
				line-height: 0.3rem;
				letter-spacing: 0.02rem;
			}
		}

		.router-div {
			cursor: pointer;
			display: flex;
			align-items: center;
			font-size: 0.16rem;
			font-family: SCBold;
			font-weight: bold;
			color: #484848;
			margin-top: 0.7rem;

			img {
				width: 0.32rem;
				margin-left: 0.1rem;
			}
		}
	}

	.goods-div {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 14.4rem;
		min-height: 10.8rem;
		margin: 0 auto;

		.name-div {
			font-size: 0.4rem;
			font-family: SCBold;
			font-weight: bold;
			color: #484848;
			margin-top: 1.5rem; 
		}

		.test-div {
			font-size: 0.14rem;
			font-family: SCBold;
			font-weight: bold;
			color: #484848;
			margin-top: 0.1rem;
		}

		.info-div {
			width: 100%;
			display: flex;
			justify-content: space-between;
			margin-top: 0.69rem;
      flex-wrap: wrap;

			.info-block1 {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 4rem;
        margin-bottom: 0.5rem;

				.info-img1 {
					width: 100%;
					position: relative;

					.img-block1 {
						width: 100%;
					}

					.img-block-goods {
						cursor: pointer;
						position: absolute;
						width: 3.24rem;
						left: 0.38rem;
						bottom: 0.7rem;
						transition: all 0.5s;
						border-radius: 0.04rem;
					}

					.img-block-goods:hover {
						width: 3.6rem;
						left: 0.2rem;
					}
				}

				.info-name1 {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					font-size: 0.36rem;
					font-family: SCBold;
					font-weight: bold;
					color: #484848;
					margin-top: 0.1rem;
				}

				.info-h {
					width: 0.3rem;
					height: 0.02rem;
					background-color: #484848;
					margin-top: 0.2rem;
				}
			}
		}


	}

	.goods-info-body {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 9999;

		.goods-info {
			width: 15rem;
			height: 7.6rem;
			background-color: #fff;
			display: flex;
			border-radius: 0.1rem 0.1rem 0.1rem 0.1rem;

			.goods-img {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 7rem;
				height: 100%;
				background-image: url('../../assets/images/goods-info-bg.png');
				background-size: 100% 100%;

				img {
					border-radius: 0.04rem;

					width: 3.3rem;
				}
			}

			.goods-test {
				position: relative;
				width: 6.2rem;
				height: 5.2rem;
				padding: 1.2rem 0.9rem;

				.info-title {
					font-size: 0.14rem;
					font-family: SCBold;
					font-weight: bold;
					color: rgba(72, 72, 72, 0.6);
					line-height: 0.14rem;
				}

				.info-title1 {
					width: 4rem;
					font-size: 0.36rem;
					font-family: SCBold;
					font-weight: bold;
					color: #484848;
					line-height: 0.36rem;
					letter-spacing: 0.03rem;
					margin: 0.2rem 0 0.4rem 0;
				}

				.info-title2 {
					width: 4rem;
					font-size: 0.2rem;
					font-family: SCBold;
					font-weight: bold;
					color: #484848;
					line-height: 0.2rem;
					margin: 0.2rem 0 0.4rem 0;
				}

				.info-title3 {
					width: 4rem;
					font-size: 0.56rem;
					font-family: SCBold;
					font-weight: bold;
					color: #484848;
					line-height: 0.56rem;
					margin: 0.2rem 0 0.4rem 0;
				}

				.esc-img {
					cursor: pointer;
					position: absolute;
					right: 0.4rem;
					top: 0.4rem;
					width: 0.24rem;
					height: 0.24rem;
				}

				.qr-code-img {
					position: absolute;
					width: 2.4rem;
					height: 2.4rem;
					right: 0.4rem;
					bottom: 1.2rem;

					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
}
</style>
