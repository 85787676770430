<template>
	<div id="app" :class="{'appMh':br === 'admin'}">
		<router-view />
	</div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
	data() {
		return {
			br:'',
		}
	},
	watch: {
		$route: {
			// $route可以用引号，也可以不用引号  监听的对象
			handler() {
				this.browserRedirect()
			},
			deep: true, // 深度观察监听 设置为 true
			immediate: true, // 第一次初始化渲染就可以监听到
		},
	},
	computed: {
		...mapGetters(['ossDomain']),
	},
	created() {
		this.getImagePre()
	},
	mounted() { },
	methods: {
		...mapMutations(['SET_OSSDOMAIN']),
		getImagePre() {
			this.getImagePreApi().then((res) => {
				this.SET_OSSDOMAIN(res.mallOssDomain)
			})
		},
		//判断是移动设备还是电脑
		browserRedirect() {
			let sUserAgent = navigator.userAgent.toLowerCase();
			let bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
			let bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
			let bIsMidp = sUserAgent.match(/midp/i) == "midp";
			let bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
			let bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
			let bIsAndroid = sUserAgent.match(/android/i) == "android";
			let bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
			let bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
			if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
				//跳转移动端页面
				this.br = 'web'
			} else {
				//跳转pc端页面
				this.br = 'admin'
			}
		}
	},
}
</script>
<style lang="less">
#app {
	width: 100%;
	height: 100vh;
}
.appMh{
	min-width: 900px;

}

body,
html {
	padding: 0;
	margin: 0;
}

@font-face {
	font-family: SCBold;
	src: url('./assets/fontFace/SC-Bold.otf');
}
</style>
