<template>
    <div class="authention-list">
        <div class="search-number-div">找到 <span style="color: red;margin: 0 5px;font-weight: 600;">{{ total }}</span>
            家符合条件的企业
        </div>

        <div style="height: calc(85vh - 105px);overflow: scroll;">
            <div class="search-info-div" v-for="item in companyList" :key="item.id" @click="openInfo(item.id)">
                <img class="search-info-img" v-if="item.logo" :src="ossDomain + item.logo" alt="">
                <div class="search-info-img" v-else></div>
                <div class="search-info-text">
                    <div class="search-info-text-name">{{ item.name || '-' }}</div>
                    <div class="search-info-text-title">
                        <div class="search-info-text-title-enterprise-p" v-if="item.label == 1">普通企业</div>
                        <div class="search-info-text-title-enterprise-y" v-if="item.label == 2">银标企业</div>
                        <div class="search-info-text-title-enterprise-j" v-if="item.label == 3">金标企业</div>
                    </div>
                    <div class="search-info-text-01">
                        <div>法定代表人：{{ item.legalPerson || '-' }}</div>
                        <div>注册资本：{{ item.registerCapital || '-' }}万</div>
                        <div>成立时间：{{ item.establishDate || '-' }}</div>
                    </div>
                    <div class="search-info-text-01">
                        <div>电话：{{ item.userMobile || '-' }}</div>
                        <div>邮箱：{{ item.userEmail || '-' }}</div>
                    </div>
                    <div class="search-info-text-01">
                        地址：{{ item.address || '-' }}
                    </div>
                    <div class="search-info-text-01">
                        经营范围：{{ item.businessScope || '-' }}
                    </div>
                </div>
            </div>
        </div>
        <div class="search-page-div">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="pageData.page" :page-sizes="[10, 20, 50, 100]" :page-size="pageData.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'authentionList',
    data() {
        return {
            pageData: {
                page: 1,
                limit: 10,
            },
        }
    },
    props: ['total', 'companyList'],
    computed: {
        ...mapGetters(['ossDomain']),
    },
    methods: {
        handleSizeChange(val) {
            this.pageData.limit = val
            this.$emit('handleSizeChange', this.pageData)
        },
        handleCurrentChange(val) {
            this.pageData.page = val
            this.$emit('handleCurrentChange', this.pageData)
        },
        openInfo(id) {
            this.$emit('openInfo', id)
        }
    },
    mounted() {

    },

}
</script>
<style lang="less" scoped>
.authention-list {
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    margin-top: 20px;
    max-height: 85vh;

    .search-number-div {
        display: flex;
        align-items: center;
        height: 44px;
        font-size: 14px;
        color: #333333;
        border-bottom: solid 1px #f2f2f2;
        padding: 0 20px;
    }

    .search-info-div {
        display: flex;
        padding: 24px 24px 30px 24px;
        border-bottom: solid 1px #f2f2f2;
        cursor: pointer;

        .search-info-img {
            width: 90px;
            height: 90px;
            margin-right: 16px;
            background-color: #6daae2;
            border-radius: 4px;
        }

        .search-info-text {
            flex: 1;

            .search-info-text-name {
                font-weight: 400;
                font-size: 18px;
                color: #333333;
            }

            .search-info-text-title {
                display: flex;
                margin: 8px 0 10px 0;

                div {
                    width: 60px;
                    height: 20px;
                    border-radius: 2px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 400;
                    font-size: 12px;
                    margin-right: 10px;
                }

                .search-info-text-title-enterprise-p {
                    color: #26AFCF;
                    background: rgba(38, 175, 207, 0.09);
                }

                .search-info-text-title-enterprise-y {
                    color: #2972FA;
                    background: rgba(41, 114, 250, 0.09);
                }

                .search-info-text-title-enterprise-j {
                    color: #8A31AB;
                    background: rgba(138, 49, 171, 0.09);
                }
            }

            .search-info-text-01 {
                font-weight: 400;
                font-size: 14px;
                color: #666666;
                display: flex;

                div {
                    flex: 1;
                }

                margin-bottom: 5px;
            }

        }
    }

    .search-page-div {
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        border-top: solid 1px #f2f2f2;

        .el-pagination {
            height: 30px;
        }
    }

}
</style>